import { Component, Input, OnInit } from '@angular/core'
import { IonContent } from '@ionic/angular'

@Component({
	selector: 'app-scroll-to-top',
	templateUrl: './scroll-to-top.component.html',
	styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit {
	@Input() ionContent: IonContent

	constructor() {}

	ngOnInit() {}

	scrollToTop() {
		this.ionContent.scrollToTop(300)
	}
}
