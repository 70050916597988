export * from './admin.interface'
export * from './club.interface'
export * from './district.interface'
export * from './environment.interface'
export * from './favorite.interface'
export * from './foreigner.interface'
export * from './generic.interface'
export * from './list.interface'
export * from './medal.interface'
export * from './opponent.interface'
export * from './player.interface'
export * from './search.interface'
export * from './user.interface'
