import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { ImageSizePipe } from './image.pipe'
import { MomentPipe } from './moment.pipe'

@NgModule({
	declarations: [MomentPipe, ImageSizePipe],
	imports: [IonicModule],
	exports: [MomentPipe, ImageSizePipe]
})
export class PipesModule {}
