import { Component, OnDestroy } from '@angular/core'
import { ReplaySubject, Subscription } from 'rxjs'

@Component({
	template: ''
})
export class BasePage implements OnDestroy {
	private subscriptions = [] as Subscription[]
	destroyed$: ReplaySubject<boolean> = new ReplaySubject(1)

	constructor() {}

	addSub(subs: Subscription | Subscription[]) {
		const subsArray = Array.isArray(subs) ? subs : [subs]
		this.subscriptions.push(...subsArray)
	}

	ngOnDestroy() {
		this.subscriptions.forEach((s) => s.unsubscribe())
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}
}
