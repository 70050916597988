import { ApplicationRef, NgModuleRef } from '@angular/core'
import { createNewHosts } from '@angularclass/hmr'

export const hmrBootstrap = (
	module: any,
	bootstrap: () => Promise<NgModuleRef<any>>
) => {
	let ngModule: NgModuleRef<any>
	module.hot.accept()
	bootstrap().then(mod => (ngModule = mod))
	module.hot.dispose(_ => {
		const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef)
		const elements = appRef.components.map(
			({ location }) => location.nativeElement
		)
		const makeVisible = createNewHosts(elements)
		ngModule.destroy()
		makeVisible()
	})
}
