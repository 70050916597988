const countries = [
	{ name: 'Afghanistan', code: 'AF' },
	{ name: 'Albanien', code: 'AL' },
	{ name: 'Algeriet', code: 'DZ' },
	{ name: 'Amerikanska Jungfruöarna', code: 'VI' },
	{ name: 'Amerikanska Samoa', code: 'AS' },
	{ name: 'Andorra', code: 'AD' },
	{ name: 'Angola', code: 'AO' },
	{ name: 'Anguilla', code: 'AI' },
	{ name: 'Antarktis', code: 'AQ' },
	{ name: 'Antigua och Barbuda', code: 'AG' },
	{ name: 'Argentina', code: 'AR' },
	{ name: 'Armenien', code: 'AM' },
	{ name: 'Aruba', code: 'AW' },
	{ name: 'Australien', code: 'AU' },
	{ name: 'Azerbajdzjan', code: 'AZ' },
	{ name: 'Bahamas', code: 'BS' },
	{ name: 'Bahrain', code: 'BH' },
	{ name: 'Bangladesh', code: 'BD' },
	{ name: 'Barbados', code: 'BB' },
	{ name: 'Belarus', code: 'BY' },
	{ name: 'Belgien', code: 'BE' },
	{ name: 'Belize', code: 'BZ' },
	{ name: 'Benin', code: 'BJ' },
	{ name: 'Bermuda', code: 'BM' },
	{ name: 'Bhutan', code: 'BT' },
	{ name: 'Bolivia', code: 'BO' },
	{ name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
	{ name: 'Bosnien och Hercegovina', code: 'BA' },
	{ name: 'Botswana', code: 'BW' },
	{ name: 'Bouvetön', code: 'BV' },
	{ name: 'Brasilien', code: 'BR' },
	{ name: 'Brittiska Jungfruöarna', code: 'VG' },
	{ name: 'Brittiska territoriet i Indiska Oceanen', code: 'IO' },
	{ name: 'Brunei', code: 'BN' },
	{ name: 'Bulgarien', code: 'BG' },
	{ name: 'Burkina Faso', code: 'BF' },
	{ name: 'Burundi', code: 'BI' },
	{ name: 'Caymanöarna', code: 'KY' },
	{ name: 'Centralafrikanska republiken', code: 'CF' },
	{ name: 'Chile', code: 'CL' },
	{ name: 'Colombia', code: 'CO' },
	{ name: 'Cooköarna', code: 'CK' },
	{ name: 'Costa Rica', code: 'CR' },
	{ name: 'Curaçao', code: 'CW' },
	{ name: 'Cypern', code: 'CY' },
	{ name: 'Danmark', code: 'DK' },
	{ name: 'Djibouti', code: 'DJ' },
	{ name: 'Dominica', code: 'DM' },
	{ name: 'Dominikanska republiken', code: 'DO' },
	{ name: 'Ecuador', code: 'EC' },
	{ name: 'Egypten', code: 'EG' },
	{ name: 'Ekvatorialguinea', code: 'GQ' },
	{ name: 'El Salvador', code: 'SV' },
	{ name: 'Elfenbenskusten', code: 'CI' },
	{ name: 'England', code: 'GB-ENG' },
	{ name: 'Eritrea', code: 'ER' },
	{ name: 'Estland', code: 'EE' },
	{ name: 'Etiopien', code: 'ET' },
	{ name: 'Falklandsöarna', code: 'FK' },
	{ name: 'Fiji', code: 'FJ' },
	{ name: 'Filippinerna', code: 'PH' },
	{ name: 'Finland', code: 'FI' },
	{ name: 'Frankrike', code: 'FR' },
	{ name: 'Franska Guyana', code: 'GF' },
	{ name: 'Franska Polynesien', code: 'PF' },
	{ name: 'Franska södra territorierna', code: 'TF' },
	{ name: 'Färöarna', code: 'FO' },
	{ name: 'Förenade Arabemiraten', code: 'AE' },
	{
		name: 'Förenta staternas mindre öar i Oceanien och Västindien',
		code: 'UM'
	},
	{ name: 'Gabon', code: 'GA' },
	{ name: 'Gambia', code: 'GM' },
	{ name: 'Georgien', code: 'GE' },
	{ name: 'Ghana', code: 'GH' },
	{ name: 'Gibraltar', code: 'GI' },
	{ name: 'Grekland', code: 'GR' },
	{ name: 'Grenada', code: 'GD' },
	{ name: 'Grönland', code: 'GL' },
	{ name: 'Guadeloupe', code: 'GP' },
	{ name: 'Guam', code: 'GU' },
	{ name: 'Guatemala', code: 'GT' },
	{ name: 'Guernsey', code: 'GG' },
	{ name: 'Guinea', code: 'GN' },
	{ name: 'Guinea-Bissau', code: 'GW' },
	{ name: 'Guyana', code: 'GY' },
	{ name: 'Haiti', code: 'HT' },
	{ name: 'Heard- och McDonaldöarna', code: 'HM' },
	{ name: 'Honduras', code: 'HN' },
	{ name: 'Hongkong', code: 'HK' },
	{ name: 'Indien', code: 'IN' },
	{ name: 'Indonesien', code: 'ID' },
	{ name: 'Irak', code: 'IQ' },
	{ name: 'Iran', code: 'IR' },
	{ name: 'Irland', code: 'IE' },
	{ name: 'Island', code: 'IS' },
	{ name: 'Isle of Man', code: 'IM' },
	{ name: 'Israel', code: 'IL' },
	{ name: 'Italien', code: 'IT' },
	{ name: 'Jamaica', code: 'JM' },
	{ name: 'Japan', code: 'JP' },
	{ name: 'Jemen', code: 'YE' },
	{ name: 'Jersey', code: 'JE' },
	{ name: 'Jordanien', code: 'JO' },
	{ name: 'Julön', code: 'CX' },
	{ name: 'Kambodja', code: 'KH' },
	{ name: 'Kamerun', code: 'CM' },
	{ name: 'Kanada', code: 'CA' },
	{ name: 'Kap Verde', code: 'CV' },
	{ name: 'Kazakstan', code: 'KZ' },
	{ name: 'Kenya', code: 'KE' },
	{ name: 'Kina', code: 'CN' },
	{ name: 'Kirgizistan', code: 'KG' },
	{ name: 'Kiribati', code: 'KI' },
	{ name: 'Kokosöarna', code: 'CC' },
	{ name: 'Komorerna', code: 'KM' },
	{ name: 'Kongo-Brazzaville', code: 'CG' },
	{ name: 'Kongo-Kinshasa', code: 'CD' },
	{ name: 'Kroatien', code: 'HR' },
	{ name: 'Kuba', code: 'CU' },
	{ name: 'Kuwait', code: 'KW' },
	{ name: 'Laos', code: 'LA' },
	{ name: 'Lesotho', code: 'LS' },
	{ name: 'Lettland', code: 'LV' },
	{ name: 'Libanon', code: 'LB' },
	{ name: 'Liberia', code: 'LR' },
	{ name: 'Libyen', code: 'LY' },
	{ name: 'Liechtenstein', code: 'LI' },
	{ name: 'Litauen', code: 'LT' },
	{ name: 'Luxemburg', code: 'LU' },
	{ name: 'Macao', code: 'MO' },
	{ name: 'Madagaskar', code: 'MG' },
	{ name: 'Malawi', code: 'MW' },
	{ name: 'Malaysia', code: 'MY' },
	{ name: 'Maldiverna', code: 'MV' },
	{ name: 'Mali', code: 'ML' },
	{ name: 'Malta', code: 'MT' },
	{ name: 'Marocko', code: 'MA' },
	{ name: 'Marshallöarna', code: 'MH' },
	{ name: 'Martinique', code: 'MQ' },
	{ name: 'Mauretanien', code: 'MR' },
	{ name: 'Mauritius', code: 'MU' },
	{ name: 'Mayotte', code: 'YT' },
	{ name: 'Mexiko', code: 'MX' },
	{ name: 'Mikronesiska federationen', code: 'FM' },
	{ name: 'Moçambique', code: 'MZ' },
	{ name: 'Moldavien', code: 'MD' },
	{ name: 'Monaco', code: 'MC' },
	{ name: 'Mongoliet', code: 'MN' },
	{ name: 'Montenegro', code: 'ME' },
	{ name: 'Montserrat', code: 'MS' },
	{ name: 'Myanmar', code: 'MM' },
	{ name: 'Namibia', code: 'NA' },
	{ name: 'Nauru', code: 'NR' },
	{ name: 'Nederländerna', code: 'NL' },
	{ name: 'Nepal', code: 'NP' },
	{ name: 'Nicaragua', code: 'NI' },
	{ name: 'Niger', code: 'NE' },
	{ name: 'Nigeria', code: 'NG' },
	{ name: 'Niue', code: 'NU' },
	{ name: 'Nordirland', code: 'GB-NIR' },
	{ name: 'Nordkorea', code: 'KP' },
	{ name: 'Nordmakedonien', code: 'MK' },
	{ name: 'Nordmarianerna', code: 'MP' },
	{ name: 'Norfolkön', code: 'NF' },
	{ name: 'Norge', code: 'NO' },
	{ name: 'Nya Kaledonien', code: 'NC' },
	{ name: 'Nya Zeeland', code: 'NZ' },
	{ name: 'Oman', code: 'OM' },
	{ name: 'Pakistan', code: 'PK' },
	{ name: 'Palau', code: 'PW' },
	{ name: 'Palestina', code: 'PS' },
	{ name: 'Panama', code: 'PA' },
	{ name: 'Papua Nya Guinea', code: 'PG' },
	{ name: 'Paraguay', code: 'PY' },
	{ name: 'Peru', code: 'PE' },
	{ name: 'Pitcairnöarna', code: 'PN' },
	{ name: 'Polen', code: 'PL' },
	{ name: 'Portugal', code: 'PT' },
	{ name: 'Puerto Rico', code: 'PR' },
	{ name: 'Qatar', code: 'QA' },
	{ name: 'Réunion', code: 'RE' },
	{ name: 'Rumänien', code: 'RO' },
	{ name: 'Rwanda', code: 'RW' },
	{ name: 'Ryssland', code: 'RU' },
	{ name: 'Saint Kitts och Nevis', code: 'KN' },
	{ name: 'Saint Lucia', code: 'LC' },
	{ name: 'Saint Vincent och Grenadinerna', code: 'VC' },
	{ name: 'Saint-Barthélemy', code: 'BL' },
	{ name: 'Saint-Martin', code: 'MF' },
	{ name: 'Saint-Pierre och Miquelon', code: 'PM' },
	{ name: 'Salomonöarna', code: 'SB' },
	{ name: 'Samoa', code: 'WS' },
	{ name: 'San Marino', code: 'SM' },
	{ name: 'Sankta Helena', code: 'SH' },
	{ name: 'São Tomé och Príncipe', code: 'ST' },
	{ name: 'Saudiarabien', code: 'SA' },
	{ name: 'Schweiz', code: 'CH' },
	{ name: 'Senegal', code: 'SN' },
	{ name: 'Serbien', code: 'RS' },
	{ name: 'Seychellerna', code: 'SC' },
	{ name: 'Sierra Leone', code: 'SL' },
	{ name: 'Singapore', code: 'SG' },
	{ name: 'Sint Maarten', code: 'SX' },
	{ name: 'Skottland', code: 'GB-SCT' },
	{ name: 'Slovakien', code: 'SK' },
	{ name: 'Slovenien', code: 'SI' },
	{ name: 'Somalia', code: 'SO' },
	{ name: 'Spanien', code: 'ES' },
	{ name: 'Sri Lanka', code: 'LK' },
	{ name: 'Storbritannien', code: 'GB' },
	{ name: 'Sudan', code: 'SD' },
	{ name: 'Surinam', code: 'SR' },
	{ name: 'Svalbard och Jan Mayen', code: 'SJ' },
	{ name: 'Sverige', code: 'SE' },
	{ name: 'Swaziland', code: 'SZ' },
	{ name: 'Sydafrika', code: 'ZA' },
	{ name: 'Sydgeorgien', code: 'GS' },
	{ name: 'Sydkorea', code: 'KR' },
	{ name: 'Sydsudan', code: 'SS' },
	{ name: 'Syrien', code: 'SY' },
	{ name: 'Tadzjikistan', code: 'TJ' },
	{ name: 'Taiwan', code: 'TW' },
	{ name: 'Tanzania', code: 'TZ' },
	{ name: 'Tchad', code: 'TD' },
	{ name: 'Thailand', code: 'TH' },
	{ name: 'Tjeckien', code: 'CZ' },
	{ name: 'Togo', code: 'TG' },
	{ name: 'Tokelauöarna', code: 'TK' },
	{ name: 'Tonga', code: 'TO' },
	{ name: 'Trinidad och Tobago', code: 'TT' },
	{ name: 'Tunisien', code: 'TN' },
	{ name: 'Turkiet', code: 'TR' },
	{ name: 'Turkmenistan', code: 'TM' },
	{ name: 'Turks- och Caicosöarna', code: 'TC' },
	{ name: 'Tuvalu', code: 'TV' },
	{ name: 'Tyskland', code: 'DE' },
	{ name: 'Uganda', code: 'UG' },
	{ name: 'Ukraina', code: 'UA' },
	{ name: 'Ungern', code: 'HU' },
	{ name: 'Uruguay', code: 'UY' },
	{ name: 'USA', code: 'US' },
	{ name: 'Uzbekistan', code: 'UZ' },
	{ name: 'Vanuatu', code: 'VU' },
	{ name: 'Vatikanstaten', code: 'VA' },
	{ name: 'Venezuela', code: 'VE' },
	{ name: 'Vietnam', code: 'VN' },
	{ name: 'Västsahara', code: 'EH' },
	{ name: 'Wales', code: 'GB-WLS' },
	{ name: 'Wallis- och Futunaöarna', code: 'WF' },
	{ name: 'Zambia', code: 'ZM' },
	{ name: 'Zimbabwe', code: 'ZW' },
	{ name: 'Åland', code: 'AX' },
	{ name: 'Österrike', code: 'AT' },
	{ name: 'Östtimor', code: 'TL' }
]

countries.sort((a, b) => a.name.localeCompare(b.name))

export default countries
