<ion-header>
	<ion-toolbar>
		<ion-buttons slot="secondary">
			<ion-button (click)="dismiss()"> Tillbaka </ion-button>
		</ion-buttons>
		<ion-title>Lägg till Medalj</ion-title>
		<ion-buttons slot="end">
			<ion-button
				(click)="save()"
				[disabled]="!(player.firstName && year && event && value) || (isTeam && !comment)"
			>
				Spara
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-list>
		<ion-segment
			[(ngModel)]="championshipType"
			(ngModelChange)="setDefaultValue()"
			[color]="championshipType == 'SM' ? 'primary' : (championshipType == 'EM' ? 'success' : (championshipType == 'VM' ? 'danger' : 'warning'))"
		>
			<ion-segment-button value="SM">
				<ion-label>SM</ion-label>
				<ion-icon *ngIf="championshipType == 'SM'" color="primary" name="trophy"></ion-icon>
				<ion-icon *ngIf="championshipType != 'SM'" name="trophy-outline"></ion-icon>
			</ion-segment-button>
			<ion-segment-button value="EM">
				<ion-label>EM</ion-label>
				<ion-icon *ngIf="championshipType == 'EM'" color="success" name="map"></ion-icon>
				<ion-icon *ngIf="championshipType != 'EM'" name="map-outline"></ion-icon>
			</ion-segment-button>
			<ion-segment-button value="VM">
				<ion-label>VM</ion-label>
				<ion-icon *ngIf="championshipType == 'VM'" color="danger" name="earth"></ion-icon>
				<ion-icon *ngIf="championshipType != 'VM'" name="earth-outline"></ion-icon>
			</ion-segment-button>
			<ion-segment-button value="OS">
				<ion-label>OS</ion-label>
				<ion-icon *ngIf="championshipType == 'OS'" color="dark" name="diamond"></ion-icon>
				<ion-icon *ngIf="championshipType != 'OS'" name="diamond-outline"></ion-icon>
			</ion-segment-button>
		</ion-segment>
		<ion-segment
			class="values"
			[(ngModel)]="value"
			[ngClass]="{gold: value == 'GULD', silver: value == 'SILVER', bronze: value == 'BRONS'}"
		>
			<ion-segment-button value="GULD">
				<ion-label>GULD</ion-label>
				<ion-icon *ngIf="value == 'GULD'" color="gold" name="medal"></ion-icon>
				<ion-icon *ngIf="value != 'GULD'" name="medal-outline"></ion-icon>
			</ion-segment-button>
			<ion-segment-button value="SILVER" [disabled]="championshipType === 'SM'">
				<ion-label>SILVER</ion-label>
				<ion-icon *ngIf="value == 'SILVER'" color="silver" name="medal"></ion-icon>
				<ion-icon *ngIf="value != 'SILVER'" name="medal-outline"></ion-icon>
			</ion-segment-button>
			<ion-segment-button value="BRONS" [disabled]="championshipType === 'SM'">
				<ion-label>BRONS</ion-label>
				<ion-icon *ngIf="value == 'BRONS'" color="bronze" name="medal"></ion-icon>
				<ion-icon *ngIf="value != 'BRONS'" name="medal-outline"></ion-icon>
			</ion-segment-button>
		</ion-segment>
		<ion-item lines="full">
			<ion-icon slot="start" name="calendar-number"></ion-icon>
			<ion-input placeholder="Årtal" type="number" [(ngModel)]="year" min="1931" max="2050"></ion-input>
		</ion-item>

		<ion-item lines="full" button [detail]="!event" id="select-event" (click)="scroll()">
			<ion-icon slot="start" name="male-female"></ion-icon>
			<ion-input placeholder="Klass" [(ngModel)]="event"></ion-input>
		</ion-item>

		<ion-item lines="full">
			<ion-icon slot="start" name="text"></ion-icon>
			<ion-textarea placeholder="Kommentar" [(ngModel)]="comment"></ion-textarea>
		</ion-item>
		<ng-container *ngIf="!player.firstName">
			<ion-item disabled="true" lines="full">
				<ion-label>Välj spelare</ion-label>
			</ion-item>
		</ng-container>
		<ng-container *ngIf="player.firstName">
			<ion-item lines="full" (click)="clearPlayer()">
				<ion-icon slot="start" name="female" color="danger" *ngIf="player.isFemale"></ion-icon>
				<ion-icon slot="start" name="male" color="primary" *ngIf="player.isMale"></ion-icon>
				<ion-label>
					<h3>{{player.firstName}} <strong>{{player.lastName}}</strong></h3>
					<p>{{player.club}} ({{player.ranking}})</p>
				</ion-label>
				<ion-chip slot="end" color="dark">
					<ion-label> {{ player.age }} ({{ player.ageClass }}) </ion-label>
				</ion-chip>
			</ion-item>
		</ng-container>
		<ng-container *ngIf="isDouble && !partner.firstName">
			<ion-item disabled="true" lines="full">
				<ion-label>Välj partner</ion-label>
			</ion-item>
		</ng-container>
		<ng-container *ngIf="partner.firstName">
			<ion-item lines="full" (click)="clearPartner()">
				<ion-icon slot="start" name="female" color="danger" *ngIf="partner.isFemale"></ion-icon>
				<ion-icon slot="start" name="male" color="primary" *ngIf="partner.isMale"></ion-icon>
				<ion-label>
					<h3>{{partner.firstName}} <strong>{{partner.lastName}}</strong></h3>
					<p>{{partner.club}} ({{partner.ranking}})</p>
				</ion-label>
				<ion-chip slot="end" color="dark">
					<ion-label> {{ partner.age }} ({{ partner.ageClass }}) </ion-label>
				</ion-chip>
			</ion-item>
		</ng-container>
		<ng-container *ngIf="isTeam">
			<ng-container *ngFor="let p of selectedPlayers; let i = index">
				<ion-item lines="full" (click)="removePlayer(i)">
					<ion-icon slot="start" name="female" color="danger" *ngIf="p.isFemale"></ion-icon>
					<ion-icon slot="start" name="male" color="primary" *ngIf="p.isMale"></ion-icon>
					<ion-label>
						<h3>{{p.firstName}} <strong>{{p.lastName}}</strong></h3>
						<p>{{p.club}} ({{p.ranking}})</p>
					</ion-label>
					<ion-chip slot="end" color="dark">
						<ion-label> {{ p.age }} ({{ p.ageClass }}) </ion-label>
					</ion-chip>
				</ion-item>
			</ng-container>
			<ion-item *ngIf="!selectedPlayers.length" disabled="true" lines="full">
				<ion-label>Välj lagmedlemmar</ion-label>
			</ion-item>
		</ng-container>
	</ion-list>
	<ng-container *ngIf="!player.firstName || (isDouble && !partner.firstName) || isTeam">
		<h3 class="ion-text-center">Sök spelare</h3>
		<ion-grid class="searchFields">
			<ion-row>
				<ion-col size="4">
					<ion-searchbar
						class="searchField"
						placeholder="Förnamn"
						[(ngModel)]="filterModel.firstName"
						(ionChange)="getPlayers()"
					></ion-searchbar>
				</ion-col>
				<ion-col size="4">
					<ion-searchbar
						class="searchField"
						placeholder="Efternamn"
						[(ngModel)]="filterModel.lastName"
						(ionChange)="getPlayers()"
					></ion-searchbar>
				</ion-col>
				<ion-col size="4">
					<ion-searchbar
						class="searchField"
						placeholder="Klubb"
						[(ngModel)]="filterModel.club"
						(ionChange)="getPlayers()"
					></ion-searchbar>
				</ion-col>
			</ion-row>
		</ion-grid>
		<div *ngIf="showLoading" class="ion-text-center">
			<br />
			<ion-spinner></ion-spinner>
		</div>
		<ion-list *ngIf="players.length" lines="full">
			<ng-container *ngFor="let p of players">
				<ion-item
					*ngIf="p.playerId !== player.playerId && p.playerId !== partner.playerId && playerNotInList(p)"
					class="players"
					(click)="selectPlayer(p)"
				>
					<player-avatar slot="start" [player]="p"></player-avatar>
					<ion-label>
						<h3>{{ p.firstName }} <strong>{{ p.lastName }}</strong></h3>
						<p>
							{{ p.club }}
							<ion-icon size="small" name="female" color="danger" *ngIf="p.gender === 'K'"></ion-icon>
							<ion-icon size="small" name="male" color="primary" *ngIf="p.gender === 'M'"></ion-icon>
							({{ p.ranking }})
						</p>
					</ion-label>
					<ion-chip slot="end">
						<ion-label> {{ p.age }} ({{ p.ageClass }}) </ion-label>
					</ion-chip>
				</ion-item>
			</ng-container>
		</ion-list>
		<ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
			<ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Hämtar spelare ...">
			</ion-infinite-scroll-content>
		</ion-infinite-scroll>
		<ion-fab horizontal="end" vertical="bottom" slot="fixed">
			<ion-fab-button color="dark" (click)="scrollToTop()">
				<ion-icon name="chevron-up-circle-outline"></ion-icon>
			</ion-fab-button>
		</ion-fab>
	</ng-container>
</ion-content>

<ion-modal trigger="select-event" #modal>
	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button (click)="modal.dismiss()">Avbryt</ion-button>
				</ion-buttons>
				<ion-title>Välj klass</ion-title>
			</ion-toolbar>
			<ion-toolbar>
				<ion-searchbar placeholder="Sök klass" (ionInput)="searchbarInput($event)"></ion-searchbar>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<ion-list>
				<ion-item-group *ngFor="let e of events">
					<ion-item-divider>
						<ion-label>{{e.name}}</ion-label>
					</ion-item-divider>
					<ion-item
						*ngFor="let i of e.items"
						(click)="eventSelected(i)"
						class="eventItem"
						[class.selected]="i === event"
						[color]="i === event ? 'medium' : ''"
					>
						<ion-label>{{i}}</ion-label>
						<ion-icon slot="end" *ngIf="isFemale(i)" name="female" color="danger"></ion-icon>
						<ion-icon slot="end" *ngIf="!isFemale(i) && !isMixed(i)" name="male" color="primary"></ion-icon>
						<ion-icon slot="end" *ngIf="isMixed(i)" name="male-female"></ion-icon>
					</ion-item>
				</ion-item-group>
			</ion-list>
		</ion-content>
	</ng-template>
</ion-modal>
