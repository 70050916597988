import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Foreigner, ForeignerCreateDto, ForeignerPaging, UserAccountFilter } from '@app/shared/interfaces'
import { Observable } from 'rxjs'
import { share } from 'rxjs/operators'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class ForeignerService extends ApiService {
	private FOREIGNER_ENDPOINT: string = 'foreigners'

	constructor(http: HttpClient) {
		super(http)
	}

	create(data: ForeignerCreateDto): Observable<any> {
		const seq = this.post(this.FOREIGNER_ENDPOINT, data).pipe(share())
		return seq
	}

	remove(id: string): Observable<any> {
		const seq = this.delete(this.FOREIGNER_ENDPOINT + '/' + id).pipe(share())
		return seq
	}

	getAll(data: UserAccountFilter): Observable<ForeignerPaging> {
		return this.post(this.FOREIGNER_ENDPOINT + '/all', data).pipe(share())
	}

	update(data: ForeignerCreateDto): Observable<Foreigner> {
		return this.put(this.FOREIGNER_ENDPOINT + '/' + data!.foreignerId, data).pipe(share())
	}
}
