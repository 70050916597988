import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '@env'
import { Observable } from 'rxjs'

export class ApiService {
	private url = environment.apiUrl
	constructor(private http: HttpClient) {}

	protected get(
		endpoint: string,
		data?: any,
		options?: object
	): Observable<any> {
		let params = {} as HttpParams
		if (data) {
			params = new HttpParams({
				fromObject: data
			})
		}
		return this.request('GET', endpoint, {}, { params, ...options })
	}

	protected post(
		endpoint: string,
		data: object,
		options?: object
	): Observable<any> {
		return this.request('POST', endpoint, data)
	}

	protected put(
		endpoint: string,
		data: object,
		options?: object
	): Observable<any> {
		return this.request('PUT', endpoint, data, options)
	}

	protected delete(endpoint: string, options?: object): Observable<any> {
		return this.request('DELETE', endpoint, {}, options)
	}

	private request(
		method: string,
		endpoint: string,
		data: object = {},
		options: object = {}
	) {
		return this.http.request(method, this.url + endpoint, {
			...options,
			body: data
		})
	}
}
