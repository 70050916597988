import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'

import { ComponentsModule } from '@app/shared/components'
import { IonicModule } from '@ionic/angular'
import { AddForeignerModal } from './add-foreigner.modal'

const routes: Routes = [
	{
		path: '',
		component: AddForeignerModal
	}
]

@NgModule({
	declarations: [AddForeignerModal],
	imports: [
		CommonModule,
		IonicModule,
		RouterModule.forChild(routes),
		FormsModule,
		ReactiveFormsModule,
		ComponentsModule
	]
})
export class AddForeignerModalModule {}
