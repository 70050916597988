<div style="position: relative">
	<ngx-avatars
		*ngIf="!player.inactive"
		slot="start"
		[size]="size"
		[name]="playerName"
		[bgColor]="bgColor"
		[fgColor]="fontColor"
		[round]="!isList && !image"
		[cornerRadius]="isList ? 4 : 0"
		[src]="image"
	></ngx-avatars>
	<div *ngIf="player.inactive" class="inactive" [style.border-color]="bgColor">😴</div>
	<ion-label *ngIf="!noWr && getWR(player) && getWR(player) <= 200 && !player.inactive">
		<ion-badge [class.lg]="size === 75" color="warning">{{ getWR(player) }}</ion-badge>
	</ion-label>
</div>
