import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { ApiService } from './api.service'
import { District } from '@app/shared/interfaces'

@Injectable({
	providedIn: 'root'
})
export class DistrictService extends ApiService {
	private DISTRICT_ENDPOINT: string = 'districts'

	constructor(http: HttpClient) {
		super(http)
	}

	getAll(): Observable<District[]> {
		const seq = this.get(this.DISTRICT_ENDPOINT)
		return seq
	}
}
