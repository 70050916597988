import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { share } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { ApiService } from './api.service'
import { Favorite, CreateFavoriteModel } from '@app/shared/interfaces'

@Injectable({
	providedIn: 'root'
})
export class FavoriteService extends ApiService {
	private FAVORITE_ENDPOINT: string = 'favorites'

	constructor(http: HttpClient) {
		super(http)
	}

	getAll(): Observable<Favorite[]> {
		const seq = this.get(this.FAVORITE_ENDPOINT).pipe(share())
		return seq
	}

	create(data: CreateFavoriteModel): Observable<Favorite> {
		const seq = this.post(this.FAVORITE_ENDPOINT, data).pipe(share())
		return seq
	}

	remove(id: string): Observable<void> {
		const seq = this.delete(this.FAVORITE_ENDPOINT + '/' + id).pipe(share())
		return seq
	}
}
