import { Component, ViewChild, inject } from '@angular/core'
import { PlayerService } from '@app/core/services'
import { FilterModel, MedalCreateDto, MedalValueEnum, Player } from '@app/shared/interfaces'
import { scrollToOption } from '@app/shared/utils'
import { IonContent, ModalController } from '@ionic/angular'

const events = [
	{ name: 'Senior', items: ['HS', 'DS', 'HD', 'DD', 'XD', 'HL', 'DL'] },
	{
		name: 'Ungdom',
		items: [
			'F11',
			'F12',
			'F13',
			'F14',
			'F15',
			'F16',
			'DJ17',
			'DJ18',
			'DJ20',
			// GIRLS DUBBEL
			'FD12',
			'FD13',
			'FD14',
			'FD15',
			'FD16',
			'DD17',
			'DD18',
			'DD20',
			// BOYS SINGEL
			'P11',
			'P12',
			'P13',
			'P14',
			'P15',
			'P16',
			'HJ17',
			'HJ18',
			'HJ20',
			// BOYS DUBBEL
			'PD12',
			'PD13',
			'PD14',
			'PD15',
			'PD16',
			'HD17',
			'HD18',
			'HD20',
			// MIXED DUBBEL
			'XD12',
			'XD13',
			'XD14',
			'XD15',
			'XD16',
			'XD17',
			'XD18',
			'XD20',
			// BOYS TEAMS
			'PL13',
			'PL14',
			'PL15',
			'PL16',
			'HL17',
			'HL18',
			// GIRLS TEAM
			'FL13',
			'FL14',
			'FL15',
			'FL16',
			'DL17',
			'DL18',
			// MIXED TEAM
			'XL18',
			'XL12'
		]
	},
	{
		name: 'Veteran',
		items: [
			'HS30',
			'HS35',
			'HS40',
			'HS45',
			'HS50',
			'HS55',
			'HS60',
			'HS65',
			'HS70',
			'HS75',
			'HS80',
			'HS85',
			'HS90',
			// WOMEN VETERAN SINGEL
			'DS30',
			'DS35',
			'DS40',
			'DS45',
			'DS50',
			'DS55',
			'DS60',
			'DS65',
			'DS70',
			'DS75',
			'DS80',
			'DS85',
			'DS90',
			// MEN VETERAN DUBBLES
			'HD30',
			'HD35',
			'HD40',
			'HD45',
			'HD50',
			'HD55',
			'HD60',
			'HD65',
			'HD70',
			'HD75',
			'HD80',
			'HD85',
			'HD90',
			// WOMEN VETERAN DUBBLES
			'DD30',
			'DD35',
			'DD40',
			'DD45',
			'DD50',
			'DD55',
			'DD60',
			'DD65',
			'DD70',
			'DD75',
			'DD80',
			'DD85',
			'DD90',
			// MIXED DUBBLES VETERAN
			'XD30',
			'XD35',
			'XD40',
			'XD45',
			'XD50',
			'XD55',
			'XD60',
			'XD65',
			'XD70',
			'XD75',
			'XD80',
			'XD85',
			'XD90',
			// MEN VETERAN TEAM
			'HL30',
			'HL40',
			'HL50',
			'HL60',
			'HL70',
			'HL80',
			// WOMEN VETERAN TEAM
			'DL30',
			'DL40',
			'DL50'
		]
	}
]

@Component({
	selector: 'app-add-medal-modal',
	templateUrl: './add-medal.modal.html',
	styleUrls: ['./add-medal.modal.scss']
})
export class AddMedalModal {
	filterModel = { limit: 15 } as FilterModel
	players: Player[] = []
	showLoading = false
	timeout: number

	@ViewChild(IonContent, { static: false })
	ionContent: IonContent

	modalCtrl = inject(ModalController)
	playerService = inject(PlayerService)

	player = {} as Player
	partner = {} as Player
	selectedPlayers = [] as Player[]

	event: string
	comment: string
	championshipType: string = 'SM'
	value: MedalValueEnum = MedalValueEnum.GOLD
	year: number

	events: any[] = [...events]

	ngOnInit() {
		this.getPlayers()
	}

	dismiss() {
		this.modalCtrl.dismiss()
	}

	save() {
		if (this.player.playerId) {
			const newMedal: MedalCreateDto = {
				event: this.event,
				comment: this.comment,
				year: this.year,
				championshipType: this.championshipType,
				playerId: this.player.playerId,
				partnerId: this.partner.playerId,
				playerIdList: this.isTeam
					? [this.player.playerId, ...this.selectedPlayers.map(({ playerId }) => playerId)]
					: undefined,
				value: this.value
			}

			this.modalCtrl.dismiss(newMedal)
		}
	}

	loadData(event: any) {
		setTimeout(() => {
			++this.filterModel.page
			event.target.complete()
			this.getPlayers(true)
		}, 100)
	}

	getPlayers(isInfiniteScroll = false) {
		this.showLoading = true

		if (this.timeout) {
			clearTimeout(this.timeout)
		}

		let time = 200
		if (!isInfiniteScroll) {
			this.filterModel.page = 1
		} else {
			time = 0
		}

		// Filter gender
		if (this.isFemale(this.event)) {
			this.filterModel.gender = 'K'
		} else if (this.isMale(this.event)) {
			this.filterModel.gender = 'M'
		} else {
			this.filterModel.gender = undefined
		}

		this.timeout = setTimeout((_) => {
			this.playerService
				.getPlayers(this.filterModel)
				.subscribe({
					next: ({ docs: players }) => {
						if (isInfiniteScroll) {
							this.players.push(...players)
						} else {
							this.players = players
						}
					}
				})
				.add(() => (this.showLoading = false))
		}, time)
	}

	selectPlayer(player: Player) {
		if (this.isDouble && this.player.firstName) {
			this.partner = player
		}
		if (this.isTeam && this.player.firstName) {
			this.selectedPlayers.push(player)
		} else {
			this.player = player
		}
	}

	playerNotInList(player: Player) {
		return this.selectedPlayers.every((p) => p.playerId !== player.playerId)
	}

	clearPlayer() {
		this.player = {} as Player
	}

	removePlayer(index: number) {
		this.selectedPlayers.splice(index)
	}

	clearPartner() {
		this.partner = {} as Player
	}

	scrollToTop() {
		this.ionContent.scrollToTop(300)
	}

	scroll(): void {
		setTimeout(() => scrollToOption('eventItem'), 800)
	}

	searchbarInput(event: any) {
		this.events = events
			.filter((e) =>
				e.items.some((name) => name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()))
			)
			.map((e) => ({
				...e,
				items: e.items.filter((x) => x.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()))
			}))
	}

	trackItems(index: number, item: any) {
		return item.value
	}

	eventSelected(event: string) {
		this.event = event

		if (
			!(
				event.startsWith('XD') ||
				event.startsWith('HD') ||
				event.startsWith('DD') ||
				event.startsWith('PD') ||
				event.startsWith('FD')
			)
		) {
			this.partner = {} as Player
		}

		this.modalCtrl.dismiss()
	}

	setDefaultValue() {
		this.value = MedalValueEnum.GOLD
	}

	isFemale(event: string) {
		return event && (event.startsWith('F') || event.startsWith('D'))
	}

	isMale(event: string) {
		return event && (event.startsWith('P') || event.startsWith('H'))
	}

	isMixed(event: string) {
		return event && event.startsWith('X')
	}

	get isDouble() {
		return (
			this.event &&
			(this.event.startsWith('XD') ||
				this.event.startsWith('HD') ||
				this.event.startsWith('DD') ||
				this.event.startsWith('PD') ||
				this.event.startsWith('FD'))
		)
	}

	get isTeam() {
		return (
			this.event &&
			(this.event.startsWith('XL') ||
				this.event.startsWith('HL') ||
				this.event.startsWith('DL') ||
				this.event.startsWith('PL') ||
				this.event.startsWith('FL'))
		)
	}
}
