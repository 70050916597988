import { animate, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { ListService, StorageService } from '@app/core/services'
import { RankingListService } from '@app/core/services/ranking-list.service'
import { BasePage } from '@app/pages/base/base.page'
import {
	List,
	RankingList,
	UpdateListModel,
	UpdateListOrderModel,
	UpdateRankingListModel
} from '@app/shared/interfaces'
import { AlertController, IonReorderGroup, ModalController } from '@ionic/angular'
import { takeUntil } from 'rxjs/operators'

@Component({
	selector: 'app-lists',
	templateUrl: './lists.modal.html',
	styleUrls: ['./lists.modal.scss'],
	animations: [
		trigger('list', [
			transition(':leave', [
				style({ transform: 'scale(1)', opacity: 1, height: '*' }),
				animate(
					'1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
					style({
						transform: 'scale(0.5)',
						opacity: 0,
						height: '0px',
						margin: '0px'
					})
				)
			])
		])
	]
})
export class ListsModal extends BasePage implements OnInit {
	lists: List[] = []
	rankingLists: RankingList[] = []
	showLoading: boolean = false
	activeList = {} as List
	activeRankingList = {} as RankingList

	reorder: boolean = false

	@ViewChild(IonReorderGroup, { static: false })
	reorderGroup: IonReorderGroup
	@Input() autoDismiss: boolean = false

	constructor(
		private listService: ListService,
		private rankingListService: RankingListService,
		private storageService: StorageService,
		private modalCtrl: ModalController,
		private alertCtrl: AlertController
	) {
		super()
	}

	ngOnInit() {
		this.showLoading = true
		const listsState = this.storageService.listsState.pipe(takeUntil(this.destroyed$)).subscribe((lists) => {
			this.lists = lists
			this.showLoading = false
		})
		const listState = this.storageService.activeListState.pipe(takeUntil(this.destroyed$)).subscribe((lists) => {
			this.activeList = lists
		})
		const rankingListsState = this.storageService.rankingListsState
			.pipe(takeUntil(this.destroyed$))
			.subscribe((lists) => {
				this.rankingLists = lists
			})
		const activeRankingListState = this.storageService.activeRankingListState
			.pipe(takeUntil(this.destroyed$))
			.subscribe((lists) => {
				this.activeRankingList = lists
			})

		this.addSub([listsState, listState, rankingListsState, activeRankingListState])
	}

	dismiss() {
		this.modalCtrl.dismiss(this.activeList.listId ? this.activeList : null)
	}

	async remove(list: List) {
		const alert = await this.alertCtrl.create({
			header: 'Ta bort listan?',
			message: 'Vill du verkligen ta bort listan?',
			buttons: [
				{
					text: 'Avbryt',
					role: 'cancel'
				},
				{
					text: 'Ja, ta bort',
					cssClass: 'danger',
					handler: () => {
						this.listService
							.remove(list.listId)
							.pipe(takeUntil(this.destroyed$))
							.subscribe(
								(_) => {
									this.storageService.removeList(list)
									this.saveListOrder()
								},
								(err) => console.log(err)
							)
					}
				}
			]
		})

		await alert.present()
	}

	async removeRankingList(list: RankingList) {
		const alert = await this.alertCtrl.create({
			header: 'Ta bort listan?',
			message: 'Vill du verkligen ta bort listan?',
			buttons: [
				{
					text: 'Avbryt',
					role: 'cancel'
				},
				{
					text: 'Ja, ta bort',
					cssClass: 'danger',
					handler: () => {
						this.rankingListService
							.remove(list.listId)
							.pipe(takeUntil(this.destroyed$))
							.subscribe(
								(_) => {
									this.storageService.removeRankingList(list)
									this.saveListOrder()
								},
								(err) => console.log(err)
							)
					}
				}
			]
		})

		await alert.present()
	}

	update(list: List) {
		const data: UpdateListModel = { ...list }

		this.listService
			.update(list.listId, data)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((_) => this.storageService.updateList(list))
	}

	updateRanking(list: RankingList) {
		const data: UpdateRankingListModel = { name: list.name }

		this.rankingListService
			.update(list.listId, data)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((_) => {
				list.name = data.name
				this.storageService.updateRankingList(list)
			})
	}

	selectList(list: List) {
		const activeList = this.activeList.listId === list.listId ? ({} as List) : list
		this.storageService.setActiveList(activeList)
		if (this.autoDismiss) {
			this.dismiss()
		}
	}

	selectRankingList(list: RankingList) {
		const activeList = this.activeRankingList.listId === list.listId ? ({} as RankingList) : list
		this.storageService.setActiveRankingList(activeList)
		if (this.autoDismiss) {
			this.dismiss()
		}
	}

	async openEditNameAlert(list: List) {
		const alert = await this.alertCtrl.create({
			header: 'Ändra listans namn',
			inputs: [
				{
					name: 'name',
					type: 'text',
					value: list.name
				}
			],
			buttons: [
				{
					text: 'Avbryt',
					role: 'cancel'
				},
				{
					text: 'Spara',
					handler: ({ name }) => {
						list.name = name
						this.update(list)
					}
				}
			]
		})
		await alert.present()
	}

	async openEditRankingNameAlert(list: RankingList) {
		const alert = await this.alertCtrl.create({
			header: 'Ändra listans namn',
			inputs: [
				{
					name: 'name',
					type: 'text',
					value: list.name
				}
			],
			buttons: [
				{
					text: 'Avbryt',
					role: 'cancel'
				},
				{
					text: 'Spara',
					handler: ({ name }) => {
						list.name = name
						this.updateRanking(list)
					}
				}
			]
		})
		await alert.present()
	}

	doReorder(e: any) {
		const { from, to } = e.detail
		const list = this.lists.splice(from, 1)[0]
		this.lists.splice(to, 0, list)
		e.detail.complete()

		// Save list order to database
		this.saveListOrder()
	}

	private saveListOrder() {
		const data: UpdateListOrderModel[] = this.lists.map(({ listId }, i) => ({
			listId,
			order: i
		}))

		this.listService.updateOrders(data).pipe(takeUntil(this.destroyed$)).subscribe()
	}
}
