import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
	Player,
	PlayerUserDto,
	Profile,
	RegisterBindModel,
	UserAccountFilter,
	UserAccountPaging,
	UserAccountUpdateModel
} from '@app/shared/interfaces'
import { Observable } from 'rxjs'
import { map, share } from 'rxjs/operators'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class UserService extends ApiService {
	private USER_ENDPOINT: string = 'users'

	constructor(http: HttpClient) {
		super(http)
	}

	create(data: RegisterBindModel): Observable<any> {
		const seq = this.post(this.USER_ENDPOINT, data).pipe(share())
		return seq
	}

	getDashboard(): Observable<Profile> {
		const seq = this.get(this.USER_ENDPOINT + '/dashboard').pipe(
			map((res: Profile) => {
				if (res.player) {
					res.player = new Player(res.player)
				}
				return res
			})
		)
		return seq
	}

	mapPlayerToUser(data: PlayerUserDto): Observable<any> {
		return this.post(this.USER_ENDPOINT + '/player', data).pipe(share())
	}

	unMapPlayerToUser(): Observable<void> {
		return this.delete(this.USER_ENDPOINT + '/player').pipe(share())
	}

	getUsers(data: UserAccountFilter): Observable<UserAccountPaging> {
		return this.post(this.USER_ENDPOINT + '/all', data).pipe(share())
	}

	updateUser(data: UserAccountUpdateModel): Observable<UserAccountPaging> {
		return this.put(this.USER_ENDPOINT + '/' + data.userId, data).pipe(share())
	}
}
