import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { District } from '@app/shared/interfaces'
import { scrollToOption } from '@app/shared/utils'

@Component({
	selector: 'app-district-picker',
	templateUrl: './district-picker.component.html',
	styleUrls: ['./district-picker.component.scss']
})
export class DistrictPickerComponent implements OnInit {
	constructor() {}

	@Input() districts: District[] = []
	@Input() activeId: number | undefined

	@Output() onCancel = new EventEmitter<void>()
	@Output() onSelected = new EventEmitter<District>()

	ngOnInit() {
		scrollToOption('checkbox-checked')
	}

	cancelClicked() {
		this.onCancel.emit()
	}

	districtSelected(district: District) {
		this.onSelected.emit(this.activeId === district.districtId ? undefined : district)
	}
}
