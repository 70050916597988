<ion-app>
	<ion-split-pane contentId="main-content">
		<ion-menu contentId="main-content">
			<ion-content>
				<ion-list lines="none">
					<ion-list-header> Sidor </ion-list-header>
					<ion-menu-toggle autoHide="false">
						<ion-item routerLink="/tabs/search" detail="false">
							<ion-icon slot="start" color="dark" name="search"></ion-icon>
							<ion-label> Sök </ion-label>
						</ion-item>
					</ion-menu-toggle>
					<ion-menu-toggle autoHide="false">
						<ion-item routerLink="/tabs/calc" detail="false">
							<ion-icon slot="start" color="dark" name="calculator"></ion-icon>
							<ion-label> Poäng </ion-label>
						</ion-item>
					</ion-menu-toggle>
					<ion-menu-toggle autoHide="false">
						<ion-item routerLink="/tabs/head2head" detail="false" *ngIf="isLoggedIn">
							<ion-icon slot="start" color="dark" name="swap-horizontal"></ion-icon>
							<ion-label> Head 2 Head </ion-label>
						</ion-item>
					</ion-menu-toggle>
					<ion-menu-toggle autoHide="false">
						<ion-item routerLink="/tabs/stats" detail="false">
							<ion-icon slot="start" color="dark" name="podium"></ion-icon>
							<ion-label> Topplistor </ion-label>
						</ion-item>
					</ion-menu-toggle>
					<ion-menu-toggle autoHide="false">
						<ion-item routerLink="/transfers" detail="false">
							<ion-icon slot="start" color="dark" name="newspaper"></ion-icon>
							<ion-label> Övergångar </ion-label>
						</ion-item>
					</ion-menu-toggle>
					<ion-menu-toggle autoHide="false">
						<ion-item routerLink="/loans" detail="false">
							<ion-icon slot="start" color="dark" name="newspaper"></ion-icon>
							<ion-label> Seriespelsregisterade </ion-label>
						</ion-item>
					</ion-menu-toggle>
					<ion-menu-toggle autoHide="false">
						<ion-item routerLink="/about" detail="false">
							<fa-icon slot="start" size="xl" [icon]="faTT"></fa-icon>
							<ion-label> Om Pingiskalkylatorn</ion-label>
						</ion-item>
					</ion-menu-toggle>
				</ion-list>

				<ion-list *ngIf="isLoggedIn" lines="none">
					<ion-list-header> Konto </ion-list-header>
					<!-- <ion-menu-toggle autoHide="false">
						<ion-item routerLink="/tabs/profile" detail="false">
							<ion-icon slot="start" name="person-outline"></ion-icon>
							<ion-label> Profil </ion-label>
						</ion-item>
					</ion-menu-toggle> -->
					<ion-menu-toggle autoHide="false">
						<ion-item button (click)="showMyLists()" detail="false">
							<ion-icon slot="start" name="folder-outline"></ion-icon>
							<ion-label> Listor </ion-label>
						</ion-item>
					</ion-menu-toggle>
					<ion-menu-toggle autoHide="false">
						<ion-item button (click)="openFavoritesPage()" detail="false">
							<ion-icon slot="start" name="heart-outline"></ion-icon>
							<ion-label> Favoriter </ion-label>
						</ion-item>
					</ion-menu-toggle>
					<ion-menu-toggle autoHide="false">
						<ion-item button (click)="logout()" detail="false">
							<ion-icon slot="start" name="log-out-outline"></ion-icon>
							<ion-label> Logout </ion-label>
						</ion-item>
					</ion-menu-toggle>
				</ion-list>
				<ion-list *ngIf="!isLoggedIn" lines="none">
					<ion-list-header> Konto </ion-list-header>
					<ion-menu-toggle autoHide="false">
						<ion-item routerLink="/login" routerLinkActive="active" routerDirection="root" detail="false">
							<ion-icon slot="start" name="log-in-outline"></ion-icon>
							<ion-label> Login </ion-label>
						</ion-item>
					</ion-menu-toggle>
				</ion-list>
			</ion-content>
		</ion-menu>
		<ion-router-outlet id="main-content"></ion-router-outlet>
	</ion-split-pane>
</ion-app>
