<ion-header>
	<ion-toolbar>
		<ion-buttons slot="secondary">
			<ion-button (click)="dismiss()" [disabled]="reorder"> Tillbaka </ion-button>
		</ion-buttons>
		<ion-title>Mina listor</ion-title>
		<ion-buttons slot="primary" *ngIf="lists.length > 1">
			<ion-button size="small" (click)="reorder = !reorder"> {{ !reorder ? 'Sortera' : 'Spara'}} </ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<div *ngIf="!reorder; else reorderDiv">
		<h4 class="ion-text-center">Klicka på avataren för att välja en lista</h4>
		<ion-card *ngFor="let l of lists" [@list] [class.selected]="l.listId === activeList.listId">
			<ion-grid>
				<ion-row>
					<ion-col size="2" class="flex-center">
						<player-avatar [player]="l.player" (click)="selectList(l)" [isList]="true"></player-avatar>
					</ion-col>
					<ion-col size="10">
						<ion-item>
							<ion-label (click)="openEditNameAlert(l)" class="ion-text-wrap">
								<h2>{{ l.name }}</h2>
								<p>{{ l.player.name }}</p>
							</ion-label>
							<ion-button slot="end" color="danger" fill="clear" (click)="remove(l)">
								<ion-icon slot="icon-only" name="trash"></ion-icon>
							</ion-button>
						</ion-item>

						<ion-item lines="none">
							<ion-label> {{ l.permanent ? 'Permanent ' : 'Månads' }}lista </ion-label>
							<ion-toggle
								color="primary"
								[(ngModel)]="l.permanent"
								[checked]="l.permanent"
								(ngModelChange)="update(l)"
							></ion-toggle>
						</ion-item>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-card>
		<div *ngIf="showLoading" class="ion-text-center">
			<br />
			<ion-spinner></ion-spinner>
		</div>
		<h4 class="ion-text-center" *ngIf="!lists.length && !showLoading">Du har inga sparade poänglistor</h4>

		<ion-card *ngFor="let l of rankingLists" [@list] [class.selected]="l.listId === activeRankingList.listId">
			<ion-grid>
				<ion-row>
					<ion-col size="2" class="flex-center">
						<ion-icon name="podium" (click)="selectRankingList(l)"></ion-icon>
					</ion-col>
					<ion-col size="10">
						<ion-item lines="none">
							<ion-label (click)="openEditRankingNameAlert(l)" class="ion-text-wrap">
								<h2>{{ l.name }}</h2>
								<p>Antal spelare: {{ l.playersData.length }}</p>
							</ion-label>
							<ion-button slot="end" color="danger" fill="clear" (click)="removeRankingList(l)">
								<ion-icon slot="icon-only" name="trash"></ion-icon>
							</ion-button>
						</ion-item>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-card>
		<h4 class="ion-text-center" *ngIf="!rankingLists.length && !showLoading">Du har inga sparade rankinglistor</h4>
	</div>
	<ng-template #reorderDiv>
		<h4 class="ion-text-center">Dra och släpp listorna</h4>
		<ion-list>
			<ion-reorder-group (ionItemReorder)="doReorder($event)" [disabled]="!reorder">
				<ion-item *ngFor="let l of lists" class="reorderRow" [class.selected]="l.listId === activeList.listId">
					<ion-label>
						<ion-grid>
							<ion-row>
								<ion-col size="3" class="flex-center">
									<player-avatar [player]="l.player" [isList]="true"></player-avatar>
								</ion-col>
								<ion-col size="9" class="flex-center listName"> {{ l.name }} </ion-col>
							</ion-row>
						</ion-grid>
					</ion-label>
					<ion-reorder slot="end"></ion-reorder>
				</ion-item>
			</ion-reorder-group>
		</ion-list>
	</ng-template>
</ion-content>
