import { Component, Input, OnInit } from '@angular/core'
import { Player } from '@app/shared/interfaces'

@Component({
	selector: 'flag',
	templateUrl: './flag.component.html',
	styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnInit {
	@Input() player = {} as Player
	@Input() show = false

	constructor() {}

	ngOnInit() {}
}
