const originals = [
	// turquoise
	'#1ABC9C',
	// purple
	'#8E44AD',
	// yellow
	'#F1C40F',
	// blue
	'#3498DB',
	// red
	'#E74C3C',
	// orange
	'#D35400',
	// dark blue
	'#2C3E50',
	// gray
	'#7F8C8D'
]

const turquoiseShades = ['#117D67', '#093D33', '#1ABC9C', '#1CC9A7', '#17A387']
const purpleShades = ['#5A2B6E', '#CC61FA', '#8E44AD', '#9849BA', '#793A94']
const yellowShades = ['#B3910B', '#735D07', '#F1C40F', '#DEB511', '#B1900E']
const blueShades = ['#256C9C', '#16405C', '#3498DB', '#38A2E8', '#2F87C2']
const redShades = ['#A8382C', '#69231B', '#E74C3C', '#F55240', '#CF4536']
const orangeShades = ['#943B00', '#542200', '#D35400', '#E05A00', '#BA4A00']
const darkBlueShades = ['#79AADB', '#56799C', '#2C3E50', '#32475C', '#1D2A36']
const grayShades = ['#454C4D', '#61AAB1', '#7F8C8D', '#8A9899', '#677273']

const shades = [
	turquoiseShades,
	purpleShades,
	yellowShades,
	blueShades,
	redShades,
	orangeShades,
	darkBlueShades,
	grayShades
]

const colors = shades[0]
	.map((_, i) => shades.map(x => x[i]))
	.reduce((arr, a) => [...arr, ...a])

export default colors
