import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MedalCreateDto, MedalFilter, MedalPaging } from '@app/shared/interfaces'
import { Observable } from 'rxjs'
import { share } from 'rxjs/operators'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class MedalService extends ApiService {
	private MEDAL_ENDPOINT: string = 'medals'

	constructor(http: HttpClient) {
		super(http)
	}

	create(data: MedalCreateDto): Observable<any> {
		const seq = this.post(this.MEDAL_ENDPOINT, data).pipe(share())
		return seq
	}

	remove(id: string): Observable<any> {
		const seq = this.delete(this.MEDAL_ENDPOINT + '/' + id).pipe(share())
		return seq
	}

	getAll(data: MedalFilter): Observable<MedalPaging> {
		return this.post(this.MEDAL_ENDPOINT + '/all', data).pipe(share())
	}
}
