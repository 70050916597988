import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'
import { ComponentsModule } from '@app/shared/components'
import { IonicModule } from '@ionic/angular'
import { ListsModal } from './lists.modal'

const routes: Routes = [
	{
		path: '',
		component: ListsModal
	}
]

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), CommonModule, ComponentsModule],
	declarations: [ListsModal]
})
export class ListsModalModule {}
