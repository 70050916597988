import { Component, Input } from '@angular/core'

@Component({
	selector: 'medal-chip',
	template: `
		<ion-chip [color]="color" size="small" [id]="id">
			<ion-icon [name]="name" size="small"></ion-icon>
			<ion-label>{{ text }}</ion-label>
		</ion-chip>
		<ion-popover [trigger]="id" triggerAction="click" *ngIf="tip">
			<ng-template>
				<ion-content class="ion-padding">{{ tip }}</ion-content>
			</ng-template>
		</ion-popover>
	`,
	styles: [
		`
			ion-chip {
				margin-right: 0.25rem;
				margin-bottom: 0.25rem;
				padding: 0.65rem;
				ion-icon {
					width: 14px;
				}
			}

			ion-chip[size='small'] {
				height: 20px;
				font-size: 12px;
			}
		`
	]
})
export class MedalChipComponent {
	@Input() color: string
	@Input() name: string = 'medal'
	@Input() text: string | number
	@Input() tip: string

	id: string = Math.random().toString(36).substring(2)
	constructor() {}
}
