import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DbUpdate, LogPaging, LogsFilterModel, StatisticModel } from '@app/shared/interfaces'
import moment from 'moment'
import { Observable } from 'rxjs'
import { map, share } from 'rxjs/operators'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class AdminService extends ApiService {
	private ADMIN_ENDPOINT: string = 'admin'
	private LOG_ENDPOINT: string = 'logs'

	constructor(http: HttpClient) {
		super(http)
	}

	getStatistics(): Observable<StatisticModel> {
		const seq = this.get(this.ADMIN_ENDPOINT + '/statistics').pipe(share())
		return seq
	}

	resetDB(): Observable<DbUpdate> {
		const seq = this.get(this.ADMIN_ENDPOINT + '/db/reset').pipe(share())
		return seq
	}

	resetStatus(id: string): Observable<DbUpdate> {
		const seq = this.get(this.ADMIN_ENDPOINT + '/db/status/' + id).pipe(share())
		return seq
	}

	getLogs(data: LogsFilterModel): Observable<LogPaging> {
		const seq = this.post(this.LOG_ENDPOINT, data).pipe(
			map((res: LogPaging) => {
				res.docs = res.docs.map((l) => {
					l.created = moment(l.created).calendar()
					l.method = l.method.includes('DELETE') ? 'DEL' : l.method
					return l
				})
				return res
			})
		)
		return seq
	}

	// facebookLogin(): Observable<any> {
	// 	const seq = this.get(this.ADMIN_ENDPOINT + '/facebook').pipe(share())
	// 	return seq
	// }
}
