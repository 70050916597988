import { TPlayerClass } from './interfaces'

const colorPalette = {
	// Blue
	blue: [
		'#2196F3', // Blue
		'#1C77C3', // Slightly darker shade
		'#1669B5', // Slightly darker shade
		'#115BAA', // Slightly darker shade
		'#0C4D9C', // Slightly darker shade
		'#084285' // Dark grey alternative
	],

	// Indigo
	indigo: [
		'#3F51B5', // Indigo
		'#375093', // Slightly darker shade
		'#2F4683', // Slightly darker shade
		'#283C74', // Slightly darker shade
		'#203564', // Slightly darker shade
		'#182A4A' // Dark grey alternative
	],

	// Purple
	purple: [
		'#673AB7', // Purple
		'#5C33A3', // Slightly darker shade
		'#522A8F', // Slightly darker shade
		'#49227B', // Slightly darker shade
		'#401B68', // Slightly darker shade
		'#32104D' // Dark grey alternative
	],

	// // Deep Purple
	// deepPurple: [
	//   '#9C27B0', // Deep Purple
	//   '#88259A', // Slightly darker shade
	//   '#761F85', // Slightly darker shade
	//   '#65186F', // Slightly darker shade
	//   '#541159', // Slightly darker shade
	//   '#3C0D3E', // Dark grey alternative
	// ],

	// Pink
	pink: [
		'#E91E63', // Pink
		'#D31C57', // Slightly darker shade
		'#C01A4B', // Slightly darker shade
		'#AE1840', // Slightly darker shade
		'#9B1533', // Slightly darker shade
		'#820E22' // Dark grey alternative
	],

	// Red
	red: [
		'#F44336', // Red
		'#DB392D', // Slightly darker shade
		'#C13324', // Slightly darker shade
		'#AA2C1D', // Slightly darker shade
		'#921E14', // Slightly darker shade
		'#6E130D' // Dark grey alternative
	],

	// Orange
	orange: [
		'#FF5722', // Orange
		'#E8531F', // Slightly darker shade
		'#D14A1C', // Slightly darker shade
		'#BD4019', // Slightly darker shade
		'#A63515', // Slightly darker shade
		'#81240F' // Dark grey alternative
	],

	// Amber
	amber: [
		'#FF9800', // Amber
		'#E88D00', // Slightly darker shade
		'#D48500', // Slightly darker shade
		'#BD7C00', // Slightly darker shade
		'#A37100', // Slightly darker shade
		'#815500' // Dark grey alternative
	],

	// Dark Grey
	darkGrey: [
		'#424242', // Dark Grey
		'#3B3B3B', // Slightly darker shade
		'#333333', // Slightly darker shade
		'#2B2B2B', // Slightly darker shade
		'#232323' // Slightly darker
	],
	green: ['#3d8b3d', '#357e35', '#2f712f', '#295c29', '#224822', '#1c3b1c']
}

const colors2 = {
	darkMain: '#222428',
	dark2: '#2d2f33',
	dark3: '#383a3e',
	dark4: '#434548',
	dark5: '#4e5053',
	redMain: '#f04141',
	red2: '#df4156',
	red3: '#d43d51',
	red4: '#c83a4d',
	red5: '#bc3648',
	yellowMain: '#ffce00',
	yellow2: '#f2ba09',
	yellow3: '#e6b008',
	yellow4: '#d9a708',
	yellow5: '#cc9d07',
	greenMain: '#2dd36f',
	green2: '#2bc869',
	green3: '#29be64',
	green4: '#26b35e',
	green5: '#24a959',
	purpleMain: '#5260ff',
	purple2: '#5b68ff',
	purple3: '#6370ff',
	purple4: '#6c78ff',
	purple5: '#7580ff',
	turquoiseMain: '#3dc2ff',
	turquoise2: '#3ab8f2',
	turquoise3: '#37afe6',
	turquoise4: '#34a5d9',
	turquoise5: '#319bcc',
	blueMain: '#3880ff',
	blue2: '#357af2',
	blue3: '#3273e6',
	blue4: '#306dd9',
	blue5: '#2d66cc',
	greyMain: '#92949c',
	grey2: '#8b8d94',
	grey3: '#83858c',
	grey4: '#7c7e85',
	grey5: '#75767d'
}

const colors = {
	// HE DE
	A1: '#222428',
	A2: '#2d2f33',
	A3: '#383a3e',
	A4: '#434548',
	A5: '#4e5053',
	// H1 D1
	B1: '#E91E63',
	B2: '#D31C57',
	B3: '#C01A4B',
	B4: '#AE1840',
	B5: '#9B1533',
	// H2 D2
	C1: '#FF9800',
	C2: '#f29000',
	C3: '#e68900',
	C4: '#d98100',
	C5: '#cc7a00',
	// H3 D3
	D1: '#3d8b3d',
	D2: '#357e35',
	D3: '#2f712f',
	D4: '#295c29',
	D5: '#224822',
	// H4 D4
	E1: '#2196F3',
	E2: '#1C77C3',
	E3: '#1669B5',
	E4: '#115BAA',
	E5: '#0C4D9C',
	// H5 D5
	F1: '#673AB7',
	F2: '#5C33A3',
	F3: '#522A8F',
	F4: '#49227B',
	F5: '#401B68',
	// H6
	G1: '#3F51B5',
	G2: '#375093',
	G3: '#2F4683',
	G4: '#283C74',
	G5: '#203564',
	// H7
	H1: '#92949c',
	H2: '#8b8d94',
	H3: '#83858c',
	H4: '#7c7e85',
	H5: '#75767d'
}

export const getPlayerColor = (ranking: number, isMale: boolean): string => {
	let color: string = ''
	if (isMale) {
		if (ranking >= 2450) color = colors.A1
		else if (ranking >= 2400) color = colors.A2
		else if (ranking >= 2350) color = colors.A3
		else if (ranking >= 2300) color = colors.A4
		else if (ranking >= 2250) color = colors.A5
		else if (ranking >= 2200) color = colors.B1
		else if (ranking >= 2150) color = colors.B2
		else if (ranking >= 2100) color = colors.B3
		else if (ranking >= 2050) color = colors.B4
		else if (ranking >= 2000) color = colors.B5
		else if (ranking >= 1950) color = colors.C1
		else if (ranking >= 1900) color = colors.C2
		else if (ranking >= 1850) color = colors.C3
		else if (ranking >= 1800) color = colors.C4
		else if (ranking >= 1750) color = colors.C5
		else if (ranking >= 1700) color = colors.D1
		else if (ranking >= 1650) color = colors.D2
		else if (ranking >= 1600) color = colors.D3
		else if (ranking >= 1550) color = colors.D4
		else if (ranking >= 1500) color = colors.D5
		else if (ranking >= 1450) color = colors.E1
		else if (ranking >= 1400) color = colors.E2
		else if (ranking >= 1350) color = colors.E3
		else if (ranking >= 1300) color = colors.E4
		else if (ranking >= 1250) color = colors.E5
		else if (ranking >= 1200) color = colors.F1
		else if (ranking >= 1150) color = colors.F2
		else if (ranking >= 1100) color = colors.F3
		else if (ranking >= 1050) color = colors.F4
		else if (ranking >= 1000) color = colors.F5
		else if (ranking >= 950) color = colors.G1
		else if (ranking >= 900) color = colors.G2
		else if (ranking >= 850) color = colors.G3
		else if (ranking >= 800) color = colors.G4
		else if (ranking >= 750) color = colors.G5
		else if (ranking >= 700) color = colors.H1
		else if (ranking >= 650) color = colors.H2
		else if (ranking >= 600) color = colors.H3
		else if (ranking >= 550) color = colors.H4
		else color = colors.H5
	} else {
		if (ranking >= 1950) color = colors.A1
		else if (ranking >= 1900) color = colors.A2
		else if (ranking >= 1850) color = colors.A3
		else if (ranking >= 1800) color = colors.A4
		else if (ranking >= 1750) color = colors.A5
		else if (ranking >= 1700) color = colors.B1
		else if (ranking >= 1650) color = colors.B2
		else if (ranking >= 1600) color = colors.B3
		else if (ranking >= 1550) color = colors.B4
		else if (ranking >= 1500) color = colors.B5
		else if (ranking >= 1450) color = colors.C1
		else if (ranking >= 1400) color = colors.C2
		else if (ranking >= 1350) color = colors.C3
		else if (ranking >= 1300) color = colors.C4
		else if (ranking >= 1250) color = colors.C5
		else if (ranking >= 1200) color = colors.D1
		else if (ranking >= 1150) color = colors.D2
		else if (ranking >= 1100) color = colors.D3
		else if (ranking >= 1050) color = colors.D4
		else if (ranking >= 1000) color = colors.D5
		else if (ranking >= 950) color = colors.E1
		else if (ranking >= 900) color = colors.E2
		else if (ranking >= 850) color = colors.E3
		else if (ranking >= 800) color = colors.E4
		else if (ranking >= 750) color = colors.E5
		else if (ranking >= 700) color = colors.F1
		else if (ranking >= 650) color = colors.F2
		else if (ranking >= 600) color = colors.F3
		else if (ranking >= 550) color = colors.F4
		else color = colors.F5
	}

	return color
}

export const getPlayerClassColor = (ranking: number, isMale: boolean): string => {
	if (isMale) {
		if (2250 <= ranking) return colors.A1
		else if (2000 <= ranking && ranking < 2250) return colors.B1
		else if (1750 <= ranking && ranking < 2000) return colors.C1
		else if (1500 <= ranking && ranking < 1750) return colors.D1
		else if (1250 <= ranking && ranking < 1500) return colors.E1
		else if (1000 <= ranking && ranking < 1250) return colors.F1
		else if (750 <= ranking && ranking < 1000) return colors.G1
		else return colors.H1
	} else {
		if (1750 <= ranking) return colors.A1
		else if (1500 <= ranking && ranking < 1750) return colors.B1
		else if (1250 <= ranking && ranking < 1500) return colors.C1
		else if (1000 <= ranking && ranking < 1250) return colors.D1
		else if (750 <= ranking && ranking < 1000) return colors.E1
		else return colors.F1
	}
}

export const DE = colors.A1
export const D1 = colors.B1
export const D2 = colors.C1
export const D3 = colors.D1
export const D4 = colors.E1
export const D5 = colors.F1

export const HE = colors.A1
export const H1 = colors.B1
export const H2 = colors.C1
export const H3 = colors.D1
export const H4 = colors.E1
export const H5 = colors.F1
export const H6 = colors.G1
export const H7 = colors.H1

export const getClass = (isFemale: boolean, ranking: number): TPlayerClass => {
	let playerClass: TPlayerClass
	if (isFemale) {
		if (ranking >= 1750) playerClass = 'DE'
		else if (ranking >= 1500) playerClass = 'D1'
		else if (ranking >= 1250) playerClass = 'D2'
		else if (ranking >= 1000) playerClass = 'D3'
		else if (ranking >= 750) playerClass = 'D4'
		else playerClass = 'D5'
	} else {
		if (ranking >= 2250) playerClass = 'HE'
		else if (ranking >= 2000) playerClass = 'H1'
		else if (ranking >= 1750) playerClass = 'H2'
		else if (ranking >= 1500) playerClass = 'H3'
		else if (ranking >= 1250) playerClass = 'H4'
		else if (ranking >= 1000) playerClass = 'H5'
		else if (ranking >= 750) playerClass = 'H6'
		else playerClass = 'H7'
	}
	return playerClass
}

export const getClassColor = (c: TPlayerClass): string => {
	switch (c) {
		case 'DE':
		case 'HE':
			return 'a'
		case 'D1':
		case 'H1':
			return 'b'
		case 'D2':
		case 'H2':
			return 'c'
		case 'D3':
		case 'H3':
			return 'd'
		case 'D4':
		case 'H4':
			return 'e'
		case 'D5':
		case 'H5':
			return 'f'
		case 'H6':
			return 'g'
		case 'H7':
			return 'h'
		default:
			return ''
	}
}

export const scrollToOption = (className: string): void => {
	const options: HTMLCollectionOf<Element> = document.getElementsByClassName(className)
	setTimeout(() => {
		const len: number = options.length
		for (let i = 0; i < len; i++) {
			const value = (options[i] as HTMLElement).attributes[1].nodeValue
			if (
				value === 'true' ||
				value.includes('action-sheet-selected') ||
				value.includes('selected') ||
				className.includes('checkbox-checked')
			) {
				options[i].scrollIntoView({
					block: 'center',
					behavior: 'smooth'
				})
				break
			}
		}
	}, 500)
}
