import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'imageSize'
})
export class ImageSizePipe implements PipeTransform {
	transform(value: string, size: string = '80', replaceString: string = '{size}'): string {
		// Check if value is undefined, null, or empty string
		if (!value) return ''

		return value.replaceAll(replaceString, size) || ''
	}
}
