import { Injectable } from '@angular/core'
import Swal from 'sweetalert2'
import { SweetAlertPosition, SweetAlertIcon } from 'sweetalert2'

@Injectable({
	providedIn: 'root'
})
export class ToastService {
	constructor() {}

	private async showToast(
		message: string,
		icon: SweetAlertIcon = 'success',
		position: SweetAlertPosition = 'center',
		timer: number = 3000,
	) {
		return await Swal.fire({
			position,
			icon,
			title: message,
			showConfirmButton: false,
			heightAuto: false,
			timer,
			backdrop: `rgba(0,0,0,0.4)`
		})
	}

	async info(message: string) {
		return this.showToast(message, 'info')
	}

	async warn(message: string, position?: SweetAlertPosition) {
		return this.showToast(message, 'warning', position)
	}

	async error(message: string) {
		return this.showToast(message, 'error')
	}

	async ok(message: string) {
		return this.showToast(message, 'success')
	}
}
