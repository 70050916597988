import { Injectable } from '@angular/core'
import { share, map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { ApiService } from './api.service'
import {
	CreateOpponentModel,
	UpdateOpponentModel,
	Opponent,
	Player
} from '@app/shared/interfaces'

@Injectable({
	providedIn: 'root'
})
export class OpponentService extends ApiService {
	private OPPONENT_ENDPOINT: string = 'opponents'

	constructor(http: HttpClient) {
		super(http)
	}

	create(data: CreateOpponentModel): Observable<Opponent> {
		const seq = this.post(this.OPPONENT_ENDPOINT, data).pipe(
			map((o: Opponent) => {
				o.player = new Player(o.player)
				return o
			})
		)
		return seq
	}

	update(
		opponentId: string,
		data: UpdateOpponentModel
	): Observable<Opponent> {
		const seq = this.put(
			this.OPPONENT_ENDPOINT + '/' + opponentId,
			data
		).pipe(share())
		return seq
	}

	remove(id: string): Observable<void> {
		const seq = this.delete(this.OPPONENT_ENDPOINT + '/' + id).pipe(share())
		return seq
	}
}
