import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'

import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators'

import { AuthService, ToastService } from '@app/core/services'
import { TokensModel } from '@app/shared/interfaces'
import { environment } from '../../../environments/environment'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	tokenState: BehaviorSubject<string> = new BehaviorSubject<string>(null)
	isRefreshingToken: boolean = false

	constructor(private authService: AuthService, private toastService: ToastService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.isInBlockedList(request.url)) {
			next.handle(request)
		} else {
			return next.handle(this.addToken(request)).pipe(
				catchError((err) => {
					if (err instanceof HttpErrorResponse) {
						switch (err.status) {
							case 400:
								return this.handle400Error(err)
							case 401:
								return this.handle401Error(request, next)
							// case 403:
							// 	return this.handle403Error()
							default:
								return throwError(err)
						}
					} else {
						return throwError(err)
					}
				})
			)
		}
		return null
	}

	// filter out URLs where you don't want to add the token
	private isInBlockedList(url: string): boolean {
		return url === `${environment.apiUrl}/login`
	}

	private addToken(req: HttpRequest<any>) {
		if (this.authService.currentAccessToken) {
			const isAdmin = this.authService.adminState.value
			const url =
				isAdmin && req.url.endsWith('/players') ? req.url.replace('/players', '/admin/players') : req.url
			return req.clone({
				url,
				headers: new HttpHeaders({
					Authorization: `Bearer ${this.authService.currentAccessToken}`,
					'Content-Type': 'application/json'
				})
			})
		} else {
			return req
		}
	}

	private handle400Error(err) {
		//this.authService.logout()
		return throwError(err)
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		if (!this.isRefreshingToken) {
			this.tokenState.next(null)
			this.isRefreshingToken = true
			this.authService.currentAccessToken = null

			return this.authService.getNewAccessToken().pipe(
				switchMap((tokens: TokensModel) => {
					if (tokens && tokens.access_token) {
						return this.authService.storeTokens(tokens).pipe(
							switchMap((_) => {
								this.authService.currentAccessToken = tokens.access_token
								this.tokenState.next(tokens.access_token)
								return next.handle(this.addToken(request))
							})
						)
					} else {
						// User is locked
						this.authService.clearStorage()
						return of(null)
					}
				}),
				finalize(() => {
					this.isRefreshingToken = false
				})
			)
		} else {
			if (request.url.includes('refresh')) {
				// Refresh token has expired
				this.authService.clearStorage()
				this.toastService.warn('Utloggad pga inaktivitet!', 'top')
				return of(null)
			} else {
				return this.tokenState.pipe(
					filter((token) => token !== null),
					take(1),
					switchMap((_) => {
						return next.handle(this.addToken(request))
					})
				)
			}
		}
	}

	private handle403Error(): Observable<any> {
		this.authService.logout()
		return of(null)
	}
}
