import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
	CreateListModel,
	List,
	Opponent,
	Player,
	UpdateListModel,
	UpdateListOrderModel,
	UpdateOpponentOrderModel
} from '@app/shared/interfaces'
import { Observable } from 'rxjs'
import { map, share } from 'rxjs/operators'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class ListService extends ApiService {
	private LIST_ENDPOINT: string = 'lists'

	constructor(http: HttpClient) {
		super(http)
	}

	create(data: CreateListModel): Observable<List> {
		const seq = this.post(this.LIST_ENDPOINT, data).pipe(share())
		return seq
	}

	getAllLists(): Observable<List[]> {
		const seq = this.get(this.LIST_ENDPOINT).pipe(
			map((res: List[]) =>
				res.map((l) => {
					l.player = new Player(l.player)
					return l
				})
			)
		)
		return seq
	}

	getList(listId: string): Observable<Opponent[]> {
		const seq = this.get(`${this.LIST_ENDPOINT}/${listId}/opponents`).pipe(
			map((res: Opponent[]) =>
				res.map((o) => {
					o.player = new Player(o.player)
					o.open = false
					return o
				})
			)
		)
		return seq
	}

	update(listId: string, data: UpdateListModel): Observable<any> {
		const seq = this.put(this.LIST_ENDPOINT + '/' + listId, data).pipe(share())
		return seq
	}

	updateOrders(data: UpdateListOrderModel[]): Observable<void> {
		const seq = this.put(this.LIST_ENDPOINT + '/orders', data).pipe(share())
		return seq
	}

	updateOpponentOrders(listId: string, data: UpdateOpponentOrderModel[]): Observable<void> {
		const seq = this.put(`${this.LIST_ENDPOINT}/${listId}/opponents/orders`, data).pipe(share())
		return seq
	}

	remove(id: string): Observable<void> {
		const seq = this.delete(this.LIST_ENDPOINT + '/' + id).pipe(share())
		return seq
	}
}
