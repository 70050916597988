import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
	Climber,
	FilterModel,
	HFOptions,
	HallOfFame,
	HighScorer,
	IClimber,
	IHighScorer,
	Month,
	Player,
	PlayerPaging,
	Statistic,
	VersusMatch
} from '@app/shared/interfaces'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Career, Loan, Transfer } from '../../shared/interfaces/player.interface'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class PlayerService extends ApiService {
	private PLAYER_ENDPOINT: string = 'players'
	private STATS_ENDPOINT: string = 'statistics'
	private TRANSFERS_ENDPOINT: string = 'transfers'
	private LOANS_ENDPOINT: string = 'loans'

	constructor(http: HttpClient) {
		super(http)
	}

	getPlayers(data?: FilterModel): Observable<PlayerPaging> {
		const seq = this.post(this.PLAYER_ENDPOINT, data).pipe(
			map((res: PlayerPaging) => {
				res.docs = res.docs.map((p) => (p = new Player(p)))
				return res
			})
		)
		return seq
	}

	getPlayer(playerId: number): Observable<Player> {
		const seq = this.get(this.PLAYER_ENDPOINT + '/' + playerId).pipe(map((p: Player) => p && new Player(p)))
		return seq
	}

	getPlayerBadges(playerId: number): Observable<HighScorer> {
		const seq = this.get(`${this.PLAYER_ENDPOINT}/${playerId}/badges`).pipe(
			map((b: IHighScorer) => b && new HighScorer(b))
		)
		return seq
	}

	getRockets(): Observable<Climber[]> {
		const seq = this.get(this.PLAYER_ENDPOINT + '/rockets').pipe(
			map((res: IClimber[]) => res.map((c) => new Climber(c)))
		)
		return seq
	}

	getComets(): Observable<Climber[]> {
		const seq = this.get(this.PLAYER_ENDPOINT + '/comets').pipe(
			map((res: IClimber[]) => res.map((c) => new Climber(c)))
		)
		return seq
	}

	getHighScorer(): Observable<HighScorer[]> {
		const seq = this.get(this.PLAYER_ENDPOINT + '/badges').pipe(
			map((res: IHighScorer[]) => res.map((b) => new HighScorer(b)))
		)
		return seq
	}

	getVersus(id1: number, id2: number): Observable<VersusMatch[]> {
		const seq = this.get(`versus/${id1}/${id2}`).pipe()
		return seq
	}

	getStatistics(playerId: number): Observable<Statistic[]> {
		const seq = this.get(`${this.STATS_ENDPOINT}/${playerId}/months`).pipe()
		return seq
	}

	getCareerStatistics(playerId: number): Observable<Career> {
		const seq = this.get(`${this.STATS_ENDPOINT}/${playerId}/career`).pipe()
		return seq
	}

	getStatsPaging(playerId: number, page: number): Observable<Month[]> {
		const seq = this.get(`${this.STATS_ENDPOINT}/${playerId}/${page}`).pipe()
		return seq
	}

	getHallOfFame(playerId: number, body: HFOptions): Observable<HallOfFame> {
		const seq = this.post(`${this.STATS_ENDPOINT}/${playerId}/halloffame`, body).pipe()
		return seq
	}

	getTransfers(): Observable<Transfer[]> {
		const seq = this.get(this.TRANSFERS_ENDPOINT).pipe()
		return seq
	}

	getLoans(): Observable<Loan[]> {
		const seq = this.get(this.LOANS_ENDPOINT).pipe()
		return seq
	}

	removeLoan(loanId: string): Observable<Loan[]> {
		const seq = this.delete(`${this.LOANS_ENDPOINT}/${loanId}`).pipe()
		return seq
	}

	downloadExcel(playerId: number) {
		const seq = this.get(`${this.STATS_ENDPOINT}/${playerId}/export/excel`, {}, { responseType: 'blob' }).pipe()
		return seq
	}

	downloadCSV(playerId: number) {
		const seq = this.get(`${this.STATS_ENDPOINT}/${playerId}/export/csv`, {}, { responseType: 'blob' }).pipe()
		return seq
	}
}
