import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ForgotBindModel, LoginBindModel, RegisterBindModel, TokensModel } from '@app/shared/interfaces'
import { Platform } from '@ionic/angular'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { share, switchMap } from 'rxjs/operators'
import { ApiService } from './api.service'
import { FavoriteService } from './favorite.service'
import { ListService } from './list.service'
import { RankingListService } from './ranking-list.service'
import { StorageService } from './storage.service'
import { UserService } from './user.service'

@Injectable({
	providedIn: 'root'
})
export class AuthService extends ApiService {
	authenticationState = new BehaviorSubject(false)
	adminState = new BehaviorSubject(false)
	currentAccessToken: string = null

	constructor(
		private storageService: StorageService,
		private platform: Platform,
		private listService: ListService,
		private rankingListService: RankingListService,
		private favoriteService: FavoriteService,
		private userService: UserService,
		private router: Router,
		http: HttpClient
	) {
		super(http)
		this.platform.ready().then((_) => this.checkToken())
	}

	login(data: LoginBindModel): Observable<void> {
		const seq = this.post('login', data).pipe(share())
		seq.subscribe((res: TokensModel) => {
			this.storageService.setTokens(res).subscribe(async (res) => {
				const [access_token, refresh_token] = res
				this.currentAccessToken = access_token
				this.authenticationState.next(true)
				const isAdmin = await this.isAdministrator()
				this.adminState.next(isAdmin)
				this.listService.getAllLists().subscribe((lists) => {
					this.storageService.setList(lists)
					this.storageService.resetCalcStates()
				})
				this.rankingListService.getAllLists().subscribe((lists) => {
					this.storageService.setRankingLists(lists)
				})
				this.favoriteService.getAll().subscribe((favorites) => this.storageService.setFavorites(favorites))
			})
		})
		return seq
	}

	logout(): void {
		this.get('logout')
			.pipe(share())
			.subscribe((_) => this.clearStorage())
	}

	getNewAccessToken() {
		return this.storageService.getRefreshToken().pipe(
			switchMap((token) => {
				if (token) {
					const options = {
						headers: new HttpHeaders({
							Authorization: `Bearer ${token}`,
							'Content-Type': 'application/json'
						})
					}
					return this.get('refresh', null, options)
				} else {
					return of(null)
				}
			})
		)
	}

	storeTokens(tokens: TokensModel): Observable<any> {
		return this.storageService.setTokens(tokens)
	}

	clearStorage(): void {
		this.authenticationState.next(false)
		this.currentAccessToken = null
		this.adminState.next(false)
		this.storageService.clearStorage()
		this.router.navigate(['/tabs/search'])
	}

	create(data: RegisterBindModel): Observable<any> {
		return this.userService.create(data)
	}

	forgot(data: ForgotBindModel): Observable<any> {
		const seq = this.post('forgot', data).pipe(share())
		return seq
	}

	async isAdministrator(): Promise<boolean> {
		return await this.get('isAdmin').toPromise()
	}

	async checkToken() {
		const seq = this.storageService.getAccessToken().pipe(share())
		seq.subscribe(async (token) => {
			if (token) {
				this.currentAccessToken = token
				this.authenticationState.next(true)
				const isAdmin = await this.isAdministrator()
				this.adminState.next(isAdmin)
				this.listService.getAllLists().subscribe((lists) => this.storageService.setList(lists))
				this.rankingListService.getAllLists().subscribe((lists) => {
					this.storageService.setRankingLists(lists)
				})
				this.favoriteService.getAll().subscribe((favorites) => this.storageService.setFavorites(favorites))
			} else {
				this.authenticationState.next(false)
				this.adminState.next(false)
			}
		})
	}
}
