import { ApplicationRef, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { environment } from '@env'
import { faTableTennis } from '@fortawesome/free-solid-svg-icons'
import { AlertController, ModalController, Platform } from '@ionic/angular'
import { ConnectionService } from 'ngx-connection-service'
import { concat, interval } from 'rxjs'
import { filter, first, takeUntil } from 'rxjs/operators'
import { AuthService, ListService, StorageService, ToastService } from './core/services'
import { BasePage } from './pages/base/base.page'
import { ListsModal } from './pages/tabs/modals'
import { List } from './shared/interfaces'

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent extends BasePage implements OnInit {
	isLoggedIn: boolean = false
	private online: boolean = true
	private deferredPrompt: any

	faTT = faTableTennis

	constructor(
		private router: Router,
		// private platform: Platform,
		private swUpdate: SwUpdate,
		private alertCtrl: AlertController,
		private authService: AuthService,
		private modalCtrl: ModalController,
		private listService: ListService,
		private storageService: StorageService,
		private connectionService: ConnectionService,
		private toastService: ToastService,
		private appRef: ApplicationRef,
		private platform: Platform
	) {
		super()
		// if (environment.production) {
		// 	this.initializeApp()
		// }

		// Log sw events
		this.logSW()
		/**
		 * ANDROID HANDLING beforeinstallprompt
		 */
		this.platform.ready().then(() => {
			window.addEventListener('beforeinstallprompt', (event: Event) => {
				console.log('📲 beforeinstallprompt')
				event.preventDefault()
				this.deferredPrompt = event
				this.showAddToHomeScreenPrompt()
			})
		})
	}

	ngOnInit() {
		this.checkForSwUpdate()
		this.updateSw()
		this.checkNetworkConnection()

		const authState = this.authService.authenticationState.subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn))

		this.addSub([authState])
	}

	// private initializeApp() {
	// 	this.platform.ready().then(async () => {
	// 		// Create a random client code
	// 		const cid = Math.floor(Math.random() * 1000000000 + 1) + ''

	// 		this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event: any) => {
	// 			if (event instanceof NavigationEnd) {
	// 				// Listen when loading a page is completed

	// 				const params = new URLSearchParams()
	// 				params.append('tid', environment.gaKey) // Google Analytics code, not GTM code
	// 				params.append('t', 'pageview') // event
	// 				params.append('cid', cid) // client id
	// 				params.append('dl', event.url) // path
	// 				fetch(`https://www.google-analytics.com/collect?v=1&${params.toString()}`)
	// 					.then((_) => {})
	// 					.catch((err) => console.log(err))
	// 			}
	// 		})
	// 	})
	// }

	private logSW() {
		this.swUpdate.versionUpdates.pipe(takeUntil(this.destroyed$)).subscribe((evt) => {
			switch (evt.type) {
				case 'VERSION_DETECTED':
					console.log(`🟢 Downloading new app version: ${evt.version.hash}`)
					break
				case 'VERSION_READY':
					console.log(`#️⃣ Current app version: ${evt.currentVersion.hash}`)
					console.log(`🆕 New app version ready for use: ${evt.latestVersion.hash}`)
					break
				case 'VERSION_INSTALLATION_FAILED':
					console.log(`❌ Failed to install app version '${evt.version.hash}': ${evt.error}`)
					break
			}
		})
	}

	private checkForSwUpdate() {
		if (!this.swUpdate.isEnabled) {
			console.log('Not Enabled')
			return
		}
		// Allow the app to stabilize first, before starting
		// polling for updates with `interval()`.
		const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true))
		const everySixHours$ = interval(6 * 60 * 60 * 1000)
		const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$)

		everySixHoursOnceAppIsStable$.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
			try {
				const updateFound = await this.swUpdate.checkForUpdate()
				console.log(updateFound ? '🆕 A new version is available.' : '🟡 Already on the latest version.')
			} catch (err) {
				console.error('Failed to check for updates:', err)
			}
		})
	}

	private updateSw() {
		if (!this.swUpdate.isEnabled) {
			console.log('Not Enabled')
			return
		}

		this.swUpdate.versionUpdates
			.pipe(
				takeUntil(this.destroyed$),
				filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
			)
			.subscribe((evt) => this.promptAlertConfirm())
	}

	private async promptAlertConfirm() {
		const alert = await this.alertCtrl.create({
			header: 'Ny uppdatering tillgänglig',
			subHeader: `Version ${environment.appVersion}`,
			message: `Vill du ladda ner den?`,
			buttons: [
				{
					text: 'näe',
					role: 'cancel'
				},
				{
					text: 'JA',
					handler: () => {
						console.log('🔄️ Reloading application')
						window.location.reload()
					}
				}
			]
		})

		await alert.present()
	}

	private checkNetworkConnection() {
		this.connectionService
			.monitor()
			.pipe(takeUntil(this.destroyed$))
			.subscribe(({ hasNetworkConnection, hasInternetAccess }) => {
				if (hasNetworkConnection) {
					if (!this.online) {
						this.toastService.ok('Nätverk tillgänglig 😁')
					}
					this.online = true
				} else {
					this.online = false
					this.toastService.warn('Nätverk offline ❕', 'top')
				}
			})
	}

	private async showAddToHomeScreenPrompt() {
		const alert = await this.alertCtrl.create({
			message: 'För bättre upplevelse, ladda ner Webb Appen till hemskärmen',
			buttons: [
				{
					text: 'Ladda ner',
					handler: () => this.addToHomeScreen()
				},
				{
					text: 'Avbryt',
					role: 'cancel'
				}
			]
		})
		alert.present()
	}

	private addToHomeScreen() {
		if (this.deferredPrompt) {
			this.deferredPrompt.prompt()
			this.deferredPrompt.userChoice.then((choiceResult: any) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('🟢 User accepted the A2HS prompt')
				} else {
					console.log('❌ User dismissed the A2HS prompt')
				}
				this.deferredPrompt = null
			})
		}
	}

	// app route methods

	async showMyLists() {
		const modal = await this.modalCtrl.create({
			component: ListsModal,
			backdropDismiss: false
		})

		modal.onDidDismiss().then(({ data }) => {
			const list = data as List
			if (list) {
				this.storageService.setCurrentPlayer(list.player)
				this.listService
					.getList(list.listId)
					.pipe(takeUntil(this.destroyed$))
					.subscribe((opponents) => this.storageService.setOpponents(opponents))
			} else {
				this.storageService.resetCalcStates()
			}
		})

		return await modal.present()
	}

	openFavoritesPage() {
		this.router.navigate(['favorites'])
	}

	logout() {
		this.authService.logout()
	}
}
