import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class InfoService extends ApiService {
	constructor(http: HttpClient) {
		super(http)
	}

	getDbUpdate(): Observable<Date> {
		return this.get('dbupdate')
	}
}
