import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Club, ClubFilterModel, ClubPaging } from '@app/shared/interfaces'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class ClubService extends ApiService {
	private CLUB_ENDPOINT: string = 'clubs'

	constructor(http: HttpClient) {
		super(http)
	}

	getAll(data: ClubFilterModel): Observable<ClubPaging> {
		const seq = this.post(this.CLUB_ENDPOINT, data)
		return seq
	}

	search(search: string): Promise<Club> {
		const seq = this.post(this.CLUB_ENDPOINT + '/search', { search })
		return seq.toPromise()
	}
}
