import { Component, Input, OnInit } from '@angular/core'
import { TGender, TPlayerClass } from '@app/shared/interfaces'
import { getClass, getClassColor } from '@app/shared/utils'

@Component({
	selector: 'class-badge',
	templateUrl: './class-badge.component.html',
	styleUrls: ['./class-badge.component.scss']
})
export class ClassBadgeComponent implements OnInit {
	@Input() class?: TPlayerClass
	@Input() ranking?: number
	@Input() gender?: TGender

	color: string = ''

	constructor() {}

	ngOnInit() {
		if (this.class) {
			this.color = getClassColor(this.class)
		}

		if (this.gender && this.ranking) {
			// do something
			const playerClass = getClass(this.gender === 'K', this.ranking)
			this.class = playerClass
			this.color = getClassColor(playerClass)
		}
	}
}
