import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment'

@Pipe({
	name: 'moment'
})
export class MomentPipe implements PipeTransform {
	transform(date: Date | string, format: string): any {
		return moment(date).format(format)
	}
}
