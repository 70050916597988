import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { hmrBootstrap } from './hmr'

import { AppModule } from '@app/app.module'
import { environment } from '@env'

if (environment.production) {
	enableProdMode()
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.register('./ngsw-worker.js').then((registration) => {
			registration.addEventListener('updatefound', () => {
				const installingWorker = registration.installing
				if (installingWorker) {
					installingWorker.addEventListener('statechange', () => {
						if (installingWorker.state === 'installed') {
							if (navigator.serviceWorker.controller) {
								// New update available
								const updateEvent = new CustomEvent('swUpdate', { detail: {} })
								document.dispatchEvent(updateEvent)
							} else {
								// First-time load or previous version not yet controlled
							}
						}
					})
				}
			})
		})
	}
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)

if (environment.hmr) {
	if (module['hot']) {
		hmrBootstrap(module, bootstrap)
	} else {
		console.error('HMR is not enabled for webpack-dev-server!')
		console.log('Are you using the --hmr flag for ng serve?')
	}
} else {
	bootstrap().catch((err) => console.log(err))
}
