import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AdminGuard, AuthGuard } from './core/guards'

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule)
	},
	{
		path: 'about',
		loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule)
	},
	{
		path: 'player-detail/:playerId',
		loadChildren: () => import('./pages/player-detail/player-detail.module').then((m) => m.PlayerDetailPageModule)
	},
	{
		path: 'player-stats/:playerId',
		canMatch: [AuthGuard],
		loadChildren: () => import('./pages/player-stats/player-stats.module').then((m) => m.PlayerStatsPageModule)
	},
	{
		path: 'hall-of-fame',
		canMatch: [AuthGuard],
		loadChildren: () => import('./pages/hall-of-fame/hall-of-fame.module').then((m) => m.HallOfFamePageModule)
	},
	{
		path: 'favorites',
		canMatch: [AuthGuard],
		loadChildren: () => import('./pages/favorites/favorites.module').then((m) => m.FavoritesPageModule)
	},
	{
		path: 'logs',
		canMatch: [AdminGuard],
		loadChildren: () => import('./pages/logs/logs.module').then((m) => m.LogsPageModule)
	},
	{
		path: 'users',
		canMatch: [AdminGuard],
		loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersPageModule)
	},
	{
		path: 'foreigners',
		canMatch: [AdminGuard],
		loadChildren: () => import('./pages/foreigners/foreigners.module').then((m) => m.ForeignersPageModule)
	},
	{
		path: 'medals',
		canMatch: [AdminGuard],
		loadChildren: () => import('./pages/medals/medals.module').then((m) => m.MedalsPageModule)
	},
	{
		path: 'transfers',
		loadChildren: () => import('./pages/transfers/transfers.module').then((m) => m.TransfersPageModule)
	},
	{
		path: 'loans',
		loadChildren: () => import('./pages/loans/loans.module').then((m) => m.LoansPageModule)
	},
	{
		path: 'tournament-points',
		loadChildren: () =>
			import('./pages/tournament-points/tournament-points.module').then((m) => m.TournamentPointsModule)
	}
]
@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			useHash: true
		})
	],
	exports: [RouterModule]
})
export class AppRouting {}
