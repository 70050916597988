<ion-header>
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-button (click)="cancelClicked()">Avbryt</ion-button>
		</ion-buttons>
		<ion-title>Välj distrikt</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-list>
		<ion-item *ngFor="let d of districts" (click)="districtSelected(d)">
			<ion-avatar slot="start">
				<img [src]="'assets/districts/' + d.districtId + '.png'" />
			</ion-avatar>
			<ion-label>
				{{ d.alias }}
			</ion-label>
			<ion-checkbox slot="end" [value]="d" [checked]="activeId == d.districtId"> </ion-checkbox>
		</ion-item>
	</ion-list>
</ion-content>
