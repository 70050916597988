import { Component, ViewChild, inject } from '@angular/core'
import { PlayerService } from '@app/core/services'
import { FilterModel, ForeignerCreateDto, Player } from '@app/shared/interfaces'
import { scrollToOption } from '@app/shared/utils'
import { IonContent, ModalController } from '@ionic/angular'
import countries from './countries'

@Component({
	selector: 'app-add-foreigner-modal',
	templateUrl: './add-foreigner.modal.html',
	styleUrls: ['./add-foreigner.modal.scss']
})
export class AddForeignerModal {
	filterModel = { limit: 15 } as FilterModel
	players: Player[] = []
	showLoading = false
	timeout: number

	@ViewChild(IonContent, { static: false })
	ionContent: IonContent

	modalCtrl = inject(ModalController)
	playerService = inject(PlayerService)

	player = {} as Player
	country: string = ''
	countryName: string = ''
	untilFurtherNotice: boolean = true
	swedishCitizen: boolean = false
	championshipApproved: boolean = false

	countries: { code: string; name: string }[] = countries

	dismiss() {
		this.modalCtrl.dismiss()
	}

	save() {
		if (this.player.playerId && this.country) {
			const newForeigner: ForeignerCreateDto = {
				country: this.country,
				untilFurtherNotice: this.untilFurtherNotice,
				swedishCitizen: this.swedishCitizen,
				championshipApproved: this.championshipApproved,
				playerId: this.player.playerId
			}

			this.modalCtrl.dismiss(newForeigner)
		}
	}

	loadData(event: any) {
		setTimeout(() => {
			++this.filterModel.page
			event.target.complete()
			this.getPlayers(true)
		}, 500)
	}

	getPlayers(isInfiniteScroll = false) {
		this.showLoading = true

		if (this.timeout) {
			clearTimeout(this.timeout)
		}

		let time = 700
		if (!isInfiniteScroll) {
			this.filterModel.page = 1
		} else {
			time = 0
		}

		this.timeout = setTimeout((_) => {
			this.playerService
				.getPlayers(this.filterModel)
				.subscribe({
					next: ({ docs: players }) => {
						if (isInfiniteScroll) {
							this.players.push(...players)
						} else {
							this.players = players
						}
					}
				})
				.add(() => (this.showLoading = false))
		}, time)
	}

	selectPlayer(player: Player) {
		this.player = player
	}

	changedToggle1() {
		if (this.untilFurtherNotice) {
			this.swedishCitizen = false
		}
	}

	changedToggle2() {
		if (this.swedishCitizen) {
			this.untilFurtherNotice = false
			this.championshipApproved = true
		}
	}

	clearPlayer() {
		this.player = {} as Player
	}

	scrollToTop() {
		this.ionContent.scrollToTop(300)
	}

	scroll(): void {
		setTimeout(() => scrollToOption('countryItem'), 800)
	}

	searchbarInput(event: any) {
		this.countries = countries.filter(({ name }) =>
			name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())
		)
	}

	trackItems(index: number, item: any) {
		return item.value
	}

	countrySelected({ name, code }: any) {
		this.country = code
		this.countryName = name
		this.countries = countries
		this.modalCtrl.dismiss()
	}
}
