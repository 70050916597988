import { inject } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from '@app/core/services'
import { Observable } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'

export const AdminGuard = (): Observable<boolean> => {
	const authService = inject(AuthService)
	const router = inject(Router)
	return authService.adminState.pipe(
		filter((val) => val !== null),
		take(1),
		map((isAdmin) => {
			if (isAdmin) return true
			router.navigate(['tabs/search'])
			return false
		})
	)
}
