import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { TokenInterceptor } from '@app/core/interceptors'
import { AddForeignerModalModule } from '@app/pages/foreigners/modals'
import { ListsModalModule } from '@app/pages/tabs/modals'
import colors from '@app/shared/avatar.colors'
import { ComponentsModule } from '@app/shared/components'
import { PipesModule } from '@app/shared/pipes'
import { environment } from '@env'
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage-angular'
import moment from 'moment'
import { AvatarModule } from 'ngx-avatars'
import {
	ConnectionServiceModule,
	ConnectionServiceOptions,
	ConnectionServiceOptionsToken
} from 'ngx-connection-service'
import { AppComponent } from './app.component'
import { AppRouting } from './app.routing'
import { AddMedalModalModule } from './pages/medals/modals'
moment.locale('sv')

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		IonicModule.forRoot({ backButtonText: 'Tillbaka' }),
		AppRouting,
		IonicStorageModule.forRoot(),
		HttpClientModule,
		AvatarModule.forRoot({ colors }),
		FontAwesomeModule,
		PipesModule,
		ComponentsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production
		}),
		FormsModule,
		ListsModalModule,
		AddForeignerModalModule,
		AddMedalModalModule,
		ConnectionServiceModule
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: ConnectionServiceOptionsToken,
			useValue: <ConnectionServiceOptions>{
				enableHeartbeat: false,
				requestMethod: 'get'
			}
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas)
	}
}
