<ion-header>
	<ion-toolbar>
		<ion-buttons slot="secondary">
			<ion-button (click)="dismiss()"> Tillbaka </ion-button>
		</ion-buttons>
		<ion-title>Lägg till Utl. spelare</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="save()" [disabled]="!(country && player.firstName)"> Spara </ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-list>
		<ion-item lines="full" button [detail]="!country" id="select-country" (click)="scroll()">
			<span *ngIf="country" slot="start" class="flag bg-{{ country | lowercase }}"></span>
			<ion-icon *ngIf="!country" slot="start" name="flag"> </ion-icon>
			<div slot="end" id="">{{ countryName }}</div>
		</ion-item>
		<ion-item lines="full">
			<ion-icon slot="start" name="infinite"></ion-icon>
			<ion-label>Tillsvidare</ion-label>
			<ion-toggle [(ngModel)]="untilFurtherNotice" (ionChange)="changedToggle1()"></ion-toggle>
		</ion-item>
		<ion-item lines="full">
			<ion-icon slot="start" name="id-card"></ion-icon>
			<ion-label>Svensk medborgare</ion-label>
			<ion-toggle [(ngModel)]="swedishCitizen" (ionChange)="changedToggle2()"></ion-toggle>
		</ion-item>
		<ion-item lines="full">
			<ion-icon slot="start" name="ribbon"></ion-icon>
			<ion-label>Kval. SM</ion-label>
			<ion-toggle [(ngModel)]="championshipApproved"></ion-toggle>
		</ion-item>
		<ng-container *ngIf="!player.firstName">
			<ion-item disabled="true" lines="full">
				<ion-label>Välj spelare</ion-label>
			</ion-item>
		</ng-container>
		<ng-container *ngIf="player.firstName">
			<ion-item lines="full" (click)="clearPlayer()">
				<ion-icon slot="start" name="female" color="danger" *ngIf="player.isFemale"></ion-icon>
				<ion-icon slot="start" name="male" color="primary" *ngIf="player.isMale"></ion-icon>
				<ion-label>
					<h3>{{player.firstName}} <strong>{{player.lastName}}</strong></h3>
					<p>{{player.club}} ({{player.playerId}})</p>
				</ion-label>
				<ion-chip slot="end" color="dark">
					<ion-label> {{ player.ranking }} </ion-label>
				</ion-chip>
			</ion-item>
		</ng-container>
	</ion-list>
	<ng-container *ngIf="!player.firstName">
		<h3 class="ion-text-center">Sök spelare</h3>
		<ion-grid class="searchFields">
			<ion-row>
				<ion-col size="4">
					<ion-searchbar
						class="searchField"
						placeholder="Förnamn"
						[(ngModel)]="filterModel.firstName"
						(ionChange)="getPlayers()"
					></ion-searchbar>
				</ion-col>
				<ion-col size="4">
					<ion-searchbar
						class="searchField"
						placeholder="Efternamn"
						[(ngModel)]="filterModel.lastName"
						(ionChange)="getPlayers()"
					></ion-searchbar>
				</ion-col>
				<ion-col size="4">
					<ion-searchbar
						class="searchField"
						placeholder="Klubb"
						[(ngModel)]="filterModel.club"
						(ionChange)="getPlayers()"
					></ion-searchbar>
				</ion-col>
			</ion-row>
		</ion-grid>
		<div *ngIf="showLoading" class="ion-text-center">
			<br />
			<ion-spinner></ion-spinner>
		</div>
		<ion-list *ngIf="players.length" lines="full">
			<ion-item *ngFor="let p of players" class="players" (click)="selectPlayer(p)">
				<player-avatar slot="start" [player]="p"></player-avatar>
				<ion-label>
					<h3>{{ p.firstName }} <strong>{{ p.lastName }}</strong></h3>
					<p class="d-flex items-center gap-1">
						{{ p.club }}
						<ion-icon size="small" name="female" color="danger" *ngIf="p.gender === 'K'"></ion-icon>
						<ion-icon size="small" name="male" color="primary" *ngIf="p.gender === 'M'"></ion-icon>
						({{ p.playerId }})
					</p>
				</ion-label>
				<ion-chip slot="end" [color]="p.point < 0 ? 'danger' : p.point > 0 ? 'success' : 'dark'">
					<ion-label> {{ p.ranking }} </ion-label>
				</ion-chip>
			</ion-item>
		</ion-list>
		<ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
			<ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Hämtar spelare ...">
			</ion-infinite-scroll-content>
		</ion-infinite-scroll>
		<ion-fab horizontal="end" vertical="bottom" slot="fixed">
			<ion-fab-button color="dark" (click)="scrollToTop()">
				<ion-icon name="chevron-up-circle-outline"></ion-icon>
			</ion-fab-button>
		</ion-fab>
	</ng-container>
</ion-content>

<ion-modal trigger="select-country" #modal>
	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button (click)="modal.dismiss()">Avbryt</ion-button>
				</ion-buttons>
				<ion-title>Välj land</ion-title>
			</ion-toolbar>
			<ion-toolbar>
				<ion-searchbar placeholder="Sök land" (ionInput)="searchbarInput($event)"></ion-searchbar>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<ion-list>
				<ion-item
					*ngFor="let c of countries"
					(click)="countrySelected(c)"
					class="countryItem"
					[class.selected]="c.code === country"
				>
					<ion-label [class.bold]="c.code === country">{{c.name}}</ion-label>
					<span slot="end" class="flag bg-{{ c.code | lowercase }}"></span>
				</ion-item>
			</ion-list>
		</ion-content>
	</ng-template>
</ion-modal>
