import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CreateRankingListModel, Player, RankingList, UpdateRankingListModel } from '@app/shared/interfaces'
import { Observable } from 'rxjs'
import { map, share } from 'rxjs/operators'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class RankingListService extends ApiService {
	private LIST_ENDPOINT: string = 'rankinglists'

	constructor(http: HttpClient) {
		super(http)
	}

	create(data: CreateRankingListModel): Observable<RankingList> {
		const seq = this.post(this.LIST_ENDPOINT, data).pipe(share())
		return seq
	}

	getAllLists(): Observable<RankingList[]> {
		const seq = this.get(this.LIST_ENDPOINT).pipe(
			map((res: RankingList[]) =>
				res.map((l) => {
					l.playersData = l.playersData.map((o) => new Player(o))
					return l
				})
			)
		)
		return seq
	}

	getList(listId: string): Observable<RankingList> {
		const seq = this.get(`${this.LIST_ENDPOINT}/${listId}`).pipe(
			map((l: RankingList) => {
				l.playersData.forEach((o) => new Player(o))
				return l
			})
		)
		return seq
	}

	update(listId: string, data: UpdateRankingListModel): Observable<void> {
		const seq = this.put(this.LIST_ENDPOINT + '/' + listId, data).pipe(share())
		return seq
	}

	addPlayer(listId: string, playerId: number): Observable<RankingList> {
		const seq = this.put(`${this.LIST_ENDPOINT}/${listId}/${playerId}`, {}).pipe(share())
		return seq
	}

	remove(id: string): Observable<void> {
		const seq = this.delete(this.LIST_ENDPOINT + '/' + id).pipe(share())
		return seq
	}

	removePlayer(listId: string, playerId: number): Observable<void> {
		const seq = this.delete(`${this.LIST_ENDPOINT}/${listId}/${playerId}`).pipe(share())
		return seq
	}
}
