import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { IClimber, Player } from '@app/shared/interfaces'
import { getPlayerColor } from '@app/shared/utils'

@Component({
	selector: 'player-avatar',
	templateUrl: './player-avatar.component.html',
	styleUrls: ['./player-avatar.component.scss']
})
export class PlayerAvatarComponent implements OnInit, OnChanges {
	@Input() name = '' as string
	@Input() ranking = 0 as number
	@Input() size = 50 as number
	@Input() wr = 0 as number
	@Input() noWr = false as boolean
	@Input() isMale = true as boolean
	@Input() player = {} as Player | IClimber
	@Input() isList = false as boolean
	@Input() image = '' as string

	bgColor: string = ''
	fontColor: string = '#fff'
	playerName: string = ''

	constructor() {}

	ngOnInit() {
		this.setPlayerName()
		this.setColorClass()
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.setColorClass()
		this.setPlayerName()
	}

	setPlayerName() {
		const res = this.name || this.player.name || ''
		const parts = res.split(' ')
		if (parts.length > 3) {
			const [first, second, ...rest] = parts
			const third = rest.reverse()[0]
			this.playerName = [first, second, third].join(' ')
		} else {
			this.playerName = res
		}
	}

	setColorClass() {
		const isMale = this.player.firstName ? this.player.isMale : this.isMale
		const ranking = (this.player && this.player.ranking) || this.ranking
		if (!ranking) {
			this.bgColor = ''
			return
		}
		this.bgColor = getPlayerColor(ranking, isMale)
	}

	getWR(obj: Player | IClimber): number | undefined {
		if ('wr' in obj) {
			return obj.wr
		}
		if (this.wr) {
			return this.wr
		}
		return undefined
	}
}
